import { TDelivery, TShipping } from '@/main/app/schema'
import { isValidDateStr } from '@/main/app/utils/dates'
import type { SubmissionWithOptIn, ItemWithData } from '@/main/features/List/listSlice'
import { isFriday, isMonday, isToday, isTomorrow, isWeekend, parseISO } from 'date-fns/esm'

import { formatPhoneNumber } from '@/cart/utils/formatPhoneNumber'
import { makeRequestId } from '@/main/app/utils/requestId'

/*
shippingLabel: 'Pickup from ACME (350 Meserole St, Bldg D)',
      startLabel: 'Pickup',
      endLabel: 'Return',
*/

const DELIVERY_TYPES = {
  roundtrip: 'Delivery (Round-Trip, 3rd party courier)',
  deliveryonly: 'Delivery (One-Way, 3rd party courier) (Renter handles return to ACME)',
  returnonly: 'Return Service (One-Way, 3rd party courier) (Renter handles pickup at ACME)',
  multitrip:
    'Delivery (Multi-Trip, 3rd party courier) (Separate Delivery and Return Pick-Up Locations)',
  pickup: 'Pickup from ACME (350 Meserole St, Bldg D)',
}

function isItemSet(x: ItemWithData) {
  return (
    x.is_set ||
    x.sku.indexOf('-S') > -1 ||
    x.sku.indexOf('-A') > -1 ||
    x.sku.indexOf('-B') > -1 ||
    x.sku.indexOf('-C') > -1
  )
}

function formatItem(item: ItemWithData) {
  return `${item.count} x ${item.title}`
}

function isDelivery(x: TShipping): x is TDelivery {
  return x.shippingMethod === 'delivery'
}

export function createFormData({ values, data, history, optIn, requestId }: SubmissionWithOptIn) {
  const fields = {
    ...values.profile,
    ...values.billing,
    ...values.dates,
    ...values.optional,
  }

  const shipping = values.shipping

  const formData = Object.keys(fields).reduce((fd, key) => {
    const value = fields[key as keyof typeof fields]

    if (value) {
      if (key === 'isUpdate') {
        if (value === 'yes') {
          fd.append(key, '1')
        }
      } else if (typeof value === 'boolean') {
        fd.append(key, `${+value}`)
      } else if (key === 'phone') {
        fd.append(key, formatPhoneNumber(value) ?? 'err')
      } else {
        fd.append(key, value)
      }
    }

    return fd
  }, new FormData())

  data.forEach((item) => {
    if (isItemSet(item)) {
      formData.append('sets[]', formatItem(item))
    } else {
      formData.append('skus[]', `${item.sku} `.repeat(item.count).trim())
    }

    const itemData = {
      id: item.id,
      count: item.count,
      title: item.title,
      link: item.link,
      sku: item.sku,
      rate: item.rate,
      set: item.is_set,
      dimensions: item.dimensions,
      media: item.media,
    }

    formData.append('items[]', JSON.stringify(itemData))
  })

  if (fields?.isUpdate === 'yes') {
    if (history?.lastStart || history?.lastEnd) {
      formData.append('subjectLabel', 'Updated (New Dates)')
    } else {
      formData.append('subjectLabel', 'Updated')
    }

    // if (recordId) {
    //   formData.append('recordId', recordId)
    // }

    if (requestId) {
      formData.append('requestId', requestId)
    }

    if (history) {
      if (history.lastStart && isValidDateStr(history.lastStart)) {
        const lastStartDate = parseISO(history.lastStart)
        const lastStartDateFormatted = `${new Intl.DateTimeFormat('en-US').format(
          lastStartDate
        )} (${new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(lastStartDate)})`

        formData.append('lastStartDate', lastStartDateFormatted)
      }
      if (history.lastEnd && isValidDateStr(history.lastEnd)) {
        const lastEndDate = parseISO(history.lastEnd)
        const lastEndDateFormatted = `${new Intl.DateTimeFormat('en-US').format(
          lastEndDate
        )} (${new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(lastEndDate)})`

        formData.append('lastEndDate', lastEndDateFormatted)
      }

      if (history.add.length > 0) {
        history.add.forEach((x) => formData.append('add[]', formatItem(x)))
      }

      if (history.remove.length > 0) {
        history.remove.forEach((x) => formData.append('remove[]', formatItem(x)))
      }

      if (history.change.length > 0) {
        history.change.forEach((x) =>
          formData.append('change[]', `${x.count - x.change} >>> ${x.count} x ${x.title}`)
        )
      }

      if (history.keep.length > 0) {
        history.keep.forEach((x) => formData.append('keep[]', formatItem(x)))
      }
    }
  } else {
    const requestId = makeRequestId()

    formData.append('subjectLabel', 'New')
    formData.append('requestId', requestId)
  }

  if (isDelivery(shipping)) {
    Object.keys(shipping.shippingDetail).forEach((s) => {
      const value = shipping.shippingDetail[s as keyof (typeof shipping)['shippingDetail']]
      const formatted =
        s === 'deliveryPhone' || s === 'returnPhone' ? formatPhoneNumber(value) : value

      formData.append(s, formatted ?? 'err')
    })

    if (shipping.shippingDetail.deliveryType !== 'returnonly') {
      formData.append('startLabel', 'Delivery')
      formData.append('hasDelivery', '1')
      formData.append(
        'deliveryLabel',
        shipping.shippingDetail.deliveryType === 'roundtrip'
          ? 'Deliver and Return To/From'
          : 'Deliver To'
      )
    } else {
      formData.append('startLabel', 'Pickup')
    }

    if (shipping.shippingDetail.deliveryType !== 'deliveryonly') {
      formData.append('endLabel', 'Return Pickup')
    } else {
      formData.append('endLabel', 'Return')
    }

    if (
      shipping.shippingDetail.deliveryType === 'multitrip' ||
      shipping.shippingDetail.deliveryType === 'returnonly'
    ) {
      formData.append('hasReturn', '1')
    }

    formData.append('shippingLabel', DELIVERY_TYPES[shipping.shippingDetail.deliveryType])
  } else {
    formData.append('shippingMethod', 'pickup')
    formData.append('startLabel', 'Pickup')
    formData.append('endLabel', 'Return')
    formData.append('shippingLabel', DELIVERY_TYPES['pickup'])
  }

  formData.append('optIn', optIn)

  try {
    if (fields?.isUpdate !== 'yes') {
      const starts = parseISO(values.dates.startDate)

      const rush = isToday(starts)
        ? 'TODAY'
        : isMonday(starts) && isWeekend(new Date())
        ? 'TODAY'
        : isTomorrow(starts)
        ? 'TOMORROW'
        : isMonday(starts) && isFriday(new Date())
        ? 'MONDAY'
        : false

      if (rush) {
        formData.append('rush', rush)
      }
    }
  } catch (err) {
    console.warn('Unexpected error during form submission', err)
  }

  return formData
}
