import { useQuery, useQueryClient } from 'react-query'
import type { EntityId } from '@reduxjs/toolkit'

import { getItemById } from '../services/acmeProp'

export const useItemQuery = (id?: EntityId) => {
  const queryClient = useQueryClient()

  return useQuery(['item', `${id}`], () => getItemById(id), {
    initialData: () =>
      queryClient.getQueryData<AcmeProp[]>('items')?.find((d) => `${d.id}` === `${id}`),
    initialDataUpdatedAt: () => queryClient.getQueryState('items')?.dataUpdatedAt,
  })
}
