import type { EntityId } from '@reduxjs/toolkit'

type PdpCartButtonAttributes = {
  text: string
  inCart: 'true' | 'false' | undefined
  isUpdating: 'true' | 'false'
}

const addToCartButtonAttributes: PdpCartButtonAttributes = {
  text: 'Add to Cart',
  inCart: 'false',
  isUpdating: 'false',
}

const removeFromCartButtonAttributes: PdpCartButtonAttributes = {
  text: 'Remove from Cart',
  inCart: 'true',
  isUpdating: 'false',
}

export function onTogglePdpCartButton(
  payload: { id: string | number; listName?: string },
  isInCart: boolean,
  button?: HTMLButtonElement | null,
  isLoading?: boolean
) {
  const btnEl =
    button ||
    (document.querySelector(
      `form[data-add-to-cart-form][data-item-id="${payload.id}"] button[type="submit"]`
    ) as HTMLButtonElement | null | undefined)

  if (!btnEl) return

  const btnText = btnEl.querySelector('.js-text') as HTMLElement | null | undefined

  if (!btnText) return

  const attributes = isInCart ? removeFromCartButtonAttributes : addToCartButtonAttributes

  const isLoadingText: 'true' | 'false' | undefined =
    isLoading === true ? 'true' : isLoading === false ? 'false' : undefined

  btnText.innerText =
    isLoading === true
      ? 'Loading ...'
      : payload.listName
      ? attributes.text.replace('Cart', payload.listName)
      : attributes.text
  btnEl.dataset.cart = attributes.inCart
  btnEl.dataset.loading = isLoadingText !== undefined ? isLoadingText : attributes.isUpdating
  btnEl.disabled = attributes.inCart === undefined || isLoading === true
  btnEl.setAttribute('aria-pressed', attributes.inCart || 'undefined')
}

export function toggleAllPdpCartButtons(
  payload: { ids?: EntityId[]; listName?: string },
  isLoading?: boolean
) {
  const forms = document.querySelectorAll('form[data-add-to-cart-form')

  if (forms.length > 0) {
    forms.forEach((form) => {
      if (!(form instanceof HTMLFormElement) || !form.dataset.itemId) return

      try {
        onTogglePdpCartButton(
          { id: form.dataset.itemId, listName: payload.listName },
          payload.ids ? payload.ids.some((id) => `${id}` == form.dataset.itemId) : false,
          form.querySelector<HTMLButtonElement>('button[type="submit"]'),
          isLoading
        )
      } catch (err) {
        console.error(err, form)
      }
    })
  }
}

export function setAddToCartButtonStatus(id: string | number, status: 'pending' | 'idle') {
  const addToCartButton: HTMLButtonElement | null = document.querySelector(
    `form[data-add-to-cart-form][data-item-id="${id}"] button[type="submit"]`
  )

  if (!addToCartButton) return
  if (status === 'pending') addToCartButton.disabled = true
  if (status === 'idle') addToCartButton.disabled = false
}
