declare global {
  interface Window {
    acmebkCartData: {
      siteUrl: string
      propApi: string
      formApi: string
      subscribeApi: string
      // isPdp: '1' | '0' | 1 | 0
      cartPage: {
        url: string
        path: string
      }
    }
    wpApiSettings: {
      apiRoot: string
      nonce: string
      action: string
    }
  }
}

type TAppSettings = {
  apiRoot: string
  nonce: string
  action: string
  siteUrl: string
  propApi: string
  formApi: string
  subscribeApi: string
  pdpEl: HTMLElement | Document
  buttonRoot: HTMLElement
  cartUrl: string
  cartPath: string
  isPdp: boolean
  formId: number
  formPaths: string[]
  mode: string
  isLocalhost: boolean
  isReady?: boolean
}

let mode

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

try {
  mode = import.meta.env.MODE
} catch (_) {
  mode = 'unknown'
}

const ACMEBK_API =
  mode !== 'development' && !isLocalhost
    ? window.acmebkCartData
    : {
        siteUrl: 'https://acmebrooklyn.local',
        propApi: 'https://acmebrooklyn.local/wp-json/wp/v2/acme_prop',
        formApi:
          'https://acmebrooklyn.local/wp-json/contact-form-7/v1/contact-forms/40379/feedback',
        subscribeApi: 'https://acmebrooklyn.local/wp-json/acmebk/v1/subscribe',
        isPdp: window.location.pathname.indexOf('cart') === -1,
        cartPage: {
          url: '/cart/',
          path: '/cart/',
        },
      }

const WP_API =
  mode !== 'development' && !isLocalhost
    ? window.wpApiSettings
    : {
        apiRoot: 'https://acmebrooklyn.local/wp-json',
        nonce: '',
        action: '',
      }

const formPaths = ['profile-and-billing', 'dates-and-shipping', 'review-and-submit']

export const appSettings: TAppSettings = {
  apiRoot: WP_API.apiRoot,
  nonce: WP_API.nonce,
  action: WP_API.action,
  siteUrl: ACMEBK_API.siteUrl,
  propApi: ACMEBK_API.propApi,
  formApi: ACMEBK_API.formApi,
  subscribeApi: ACMEBK_API.subscribeApi,
  pdpEl: document.getElementById('main') || document.querySelector('main') || document,
  buttonRoot: document.getElementById('acmebk-cart-button-root') || document.body,
  cartUrl: ACMEBK_API.cartPage?.url || '/cart/',
  cartPath:
    ACMEBK_API?.cartPage?.path ||
    ACMEBK_API?.cartPage?.url?.substring(ACMEBK_API.cartPage.url.lastIndexOf('/') + 1) ||
    '/cart/',
  isPdp: document.querySelector('form[data-add-to-cart-form') !== null,
  formId: 40379,
  formPaths,
  mode,
  isLocalhost,
}
