import { QueryClient } from 'react-query'

const queryClientOptions = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      suspense: true,
    },
  },
}

export const queryClient = new QueryClient(queryClientOptions)
