import { useCallback, useState } from 'react'

import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { Button } from '@/main/components/Button/Button'
import { ListPlus } from '@phosphor-icons/react'
import { useNavigateList } from '../../hooks/useNavigateList'

import { ListId, selectLists } from '../../listSlice'
import { ListCreateDialog } from '../ListDialogs/ListCreateDialog/ListCreateDialog'
import { ListSelectCard } from './ListSelectCard'

export function ListSelect({ className }: { className: string }) {
  const lists = useAppSelector(selectLists)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const onNavigate = useNavigateList()

  const onCloseDialog = useCallback(
    (id?: ListId) => {
      if (id) {
        onNavigate(id)
      }

      setIsDialogOpen(false)
    },
    [onNavigate]
  )

  return (
    <>
      {lists.length === 0 ? (
        <div className="twc-flex twc-flex-col twc-items-center twc-justify-center grow twc-h-1/2 twc-prose dark:twc-prose-invert">
          <p className="twc-text-2xl twc-text-center">
            Create your first project
            <br />
            and add props to your cart!
          </p>

          <div>
            <Button
              onClick={() => setIsDialogOpen(true)}
              size="large"
              className="!twc-bg-primary hover:!twc-bg-brand !twc-flex"
            >
              Create New Project <ListPlus />
            </Button>
          </div>
        </div>
      ) : (
        <>
          <ul className={className}>
            {lists.map((list) => (
              <li key={list.id}>
                <ListSelectCard list={list} />
              </li>
            ))}
            {lists.length === 1 && (
              <li key="cta-promote-projects">
                <div className="twc-rounded twc-flex twc-flex-col twc-border twc-border-sky-500 twc-border-dashed twc-p-4 twc-col-span-2 xl:twc-col-auto">
                  <h3 className="twc-text-lg">Create more than one cart</h3>
                  <p className="twc-text-sm twc-leading-normal twc-mb-1">
                    <strong>Work with multiple carts at the same time.</strong> Create another
                    project and get separate quotes for your other campaigns, sets, events, etc.!
                  </p>
                </div>
              </li>
            )}
          </ul>

          <Button onClick={() => setIsDialogOpen(true)} className="twc-mx-auto twc-my-6 !twc-flex">
            Create a new project <ListPlus className="twc-inline" />
          </Button>
        </>
      )}

      <ListCreateDialog isOpen={isDialogOpen} onClose={onCloseDialog} />
    </>
  )
}
