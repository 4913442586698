import { usePersistedValue } from '@/main/app/hooks/usePersistedValue'
import { Moon, Sun } from '@phosphor-icons/react'
import { ButtonHTMLAttributes, forwardRef } from 'react'
import { Button } from '../Button/Button'

interface ThemeButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconOnly?: boolean
}

export const ThemeButton = forwardRef<HTMLButtonElement, ThemeButtonProps>(function ThemeButton(
  { iconOnly, ...props },
  ref
) {
  const [theme, setTheme] = usePersistedValue('theme')
  const textClass = iconOnly ? 'twc-sr-only' : 'twc-not-sr-only'

  return (
    <Button
      {...props}
      variant="ghost"
      align="left"
      size="small"
      onClick={() => {
        if (theme === 'dark') {
          document.documentElement.classList.remove('twc-dark')
          document.documentElement.classList.remove('dark')
          setTheme('light')
        } else {
          document.documentElement.classList.add('twc-dark')
          document.documentElement.classList.add('dark')
          setTheme('dark')
        }
      }}
      ref={ref}
    >
      {theme === 'dark' ? (
        <>
          <Moon weight="fill" /> <span className={textClass}>Light mode</span>
        </>
      ) : (
        <>
          <Sun weight="fill" /> <span className={textClass}>Dark mode</span>
        </>
      )}
    </Button>
  )
})
