import {
  ArrowUUpLeft,
  CheckCircle,
  CircleNotch,
  ShoppingCartSimple,
  TrashSimple,
  WarningCircle,
} from '@phosphor-icons/react'

import toast from 'react-hot-toast/headless'

import type { Item } from '@/main/features/Item/itemSlice'

import { Button } from '../Button/Button'

const positionBottomLeft = 'twc-absolute twc-z-[999] twc-bottom-0'

const truncate = (str?: string) =>
  !str ? undefined : str.length > 38 ? `${str.substring(0, 38)}\u2026` : str

const onLoadingToast = (id: string, { message }: { message: string }) => {
  toast.loading(<p className="lg:mb-1">{message}</p>, {
    id,
    icon: <CircleNotch className="motion-safe:twc-animate-spin" size={18} />,
    className: `twc-bg-blue-100 twc-bg-blue-900 ${positionBottomLeft}`,
  })
}

const onAddedToast = (
  id: string,
  {
    item,
    data,
    listName,
    handleOpen,
  }: { item: Item; listName?: string; data?: AcmeProp; handleOpen?: () => void }
) => {
  toast.success(
    <>
      <p className="lg:twc-mb-1">
        Added{' '}
        <strong className="twc-font-medium">
          ({item.count}){' '}
          <a className="twc-underline" href={data?.link ?? undefined}>
            {truncate(data?.title) ?? 'Prop'}
          </a>
        </strong>{' '}
        to {listName ?? 'your cart'}.
      </p>
      {handleOpen && (
        <Button
          variant="link"
          size="small"
          className="twc-px-0 !twc-text-sm lg:!twc-text-base"
          onClick={() => {
            toast.dismiss(id)
            handleOpen()
          }}
        >
          View in {listName ?? ''} cart <ShoppingCartSimple />
        </Button>
      )}
    </>,
    {
      id,
      icon: (
        <CheckCircle
          size={18}
          weight="bold"
          className="twc-text-emerald-700 dark:twc-text-emerald-200"
        />
      ),
      className: `twc-bg-emerald-100 dark:twc-bg-emerald-900 ${positionBottomLeft}`,
    }
  )
}

const onRemovedToast = (
  id: string,
  {
    item,
    listName,
    data,
    handleUndo,
  }: { item: Item; listName?: string; data?: AcmeProp; handleUndo: () => void }
) => {
  toast.success(
    <>
      <p className="lg:twc-mb-1">
        Removed{' '}
        <strong className="twc-font-medium">
          ({item.count}){' '}
          <a className="twc-underline" href={data?.link ?? undefined}>
            {data?.title ?? 'Prop'}
          </a>
        </strong>{' '}
        from {listName ?? 'your cart'}.
      </p>
      <Button
        variant="link"
        size="small"
        className="twc-px-0 !twc-text-sm lg:!twc-text-base"
        onClick={() => {
          toast.dismiss(id)
          handleUndo()
        }}
      >
        Undo <ArrowUUpLeft />
      </Button>
    </>,
    {
      id,
      icon: (
        <TrashSimple
          size={18}
          weight="bold"
          className="twc-text-orange-800 dark:twc-text-fuchsia-200"
        />
      ),
      className: `twc-bg-orange-100 dark:twc-bg-fuchsia-900 ${positionBottomLeft}`,
    }
  )
}
const onErrorToast = (id: string, { message }: { message: string }) => {
  toast.error(<p className="lg:mb-1">{message}</p>, {
    id,
    icon: <WarningCircle size={18} />,
    className: `twc-bg-rose-200 dark:twc-bg-rose-900 ${positionBottomLeft}`,
    duration: 8000,
  })
}

export { onLoadingToast, onAddedToast, onRemovedToast, onErrorToast }
