import { forwardRef, ButtonHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'link' | 'ghost' | 'outline'
  align?: 'left' | 'right' | 'center'
  size?: 'base' | 'small' | 'large'
  as?: 'link' | 'button' | 'route'
  to?: string
  inactive?: boolean
}

const classList = {
  base: 'twc-inline-flex twc-items-center twc-py-0.5 motion-safe:twc-transition-colors disabled:twc-bg-zinc-300 disabled:twc-text-zinc-500',
  default: 'twc-text-inherit',
  variant: {
    primary: 'twc-bg-primary twc-text-slate-50 hover:twc-text-white hover:twc-bg-brand',
    secondary:
      'twc-bg-violet-200 twc-text-slate-900 hover:twc-bg-primary hover:twc-text-slate-50 dark:twc-bg-[#42456e] dark:twc-text-slate-50 dark:hover:twc-border-primary dark:hover:twc-bg-primary dark:hover:twc-text-white',
    link: 'twc-underline twc-text-blue-600 twc-font-medium twc-rounded-none twc-p-0 hover:twc-text-brand hover:twc-no-underline dark:twc-text-blue-300 dark:hover:twc-text-orange-300',
    outline:
      'twc-bg-transparent twc-border twc-border-slate-600 hover:twc-bg-violet-200 hover:twc-border-violet-200',
    ghost: 'twc-bg-transparent',
  },
  size: {
    base: 'twc-rounded-lg twc-text-sm xs:twc-text-base twc-leading-loose xs:twc-leading-loose sm:twc-h-10 twc-px-2 sm:twc-px-3 twc-gap-1 sm:twc-gap-1.5',
    large:
      'twc-rounded-xl twc-text-lg twc-leading-loose twc-h-10 sm:twc-h-12 twc-px-2 sm:twc-px-4 twc-gap-1.5',
    small:
      'twc-tracking-tight sm:twc-tracking-normal 2xl:twc-tracking-normal twc-rounded-md twc-leading-loose twc-text-sm md:twc-leading-loose twc-gap-1 twc-h-auto twc-px-2 twc-py-1',
  },
  modifier: {
    smallLink: 'twc-h-auto twc-px-0',
    disabledGhost: 'twc-bg-transparent twc-text-slate-400 dark:twc-text-slate-600',
    inactive: 'twc-bg-stone-300 twc-text-stone-700 hover:twc-bg-stone-400 hover:twc-text-stone-800',
  },
  align: {
    center: 'twc-justify-center twc-text-center',
    left: 'twc-justify-start twc-text-left',
    right: 'twc-justify-end twc-text-right',
  },
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    onClick,
    className = '',
    variant = 'primary',
    size = 'base',
    align = 'center',
    disabled = false,
    inactive = false,
    as = 'button',
    to = '#',
    children,
    ...props
  },
  ref
) {
  const classListConcat = [
    classList.base,
    classList.default,
    classList.size[size],
    classList.align[align],
    classList.variant[variant],
    variant === 'ghost' && disabled && classList.modifier.disabledGhost,
    inactive && classList.modifier.inactive,
    className.trim(),
  ]
    .filter(Boolean)
    .join(' ')

  return as === 'link' ? (
    <a className={classListConcat} href={to}>
      {children}
    </a>
  ) : as === 'route' ? (
    <Link className={classListConcat} to={to}>
      {children}
    </Link>
  ) : (
    <button
      type="button"
      {...props}
      className={classListConcat}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
    >
      {children}
    </button>
  )
})
