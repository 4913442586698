import { ArrowsCounterClockwise, Trash, X } from '@phosphor-icons/react'
import { AxiosError } from 'axios'

import { useAppDispatch, useAppSelector } from '@/main/app/hooks/storeHooks'
import { useAppContext } from '@/main/app/context'
import { onTogglePdpCartButton } from '@/main/app/utils/pdpButtons'

import { itemRemoved, ItemId, selectItemById } from '@/main/features/Item/itemSlice'

import { Button } from '@/main/components/Button/Button'
import { useList } from '@/main/features/List/hooks/useList'

import './error-boundary.css'

interface ItemResetErrorBoundaryProps {
  id: ItemId
  error: Error | AxiosError
  resetErrorBoundary: (...args: unknown[]) => void
}

const resolveError = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (error.response?.data.code === 'rest_post_invalid_id') {
      return {
        type: 'invalid',
        message: 'This item does not exist. Please remove from your cart.',
      }
    }
  }

  if (
    (error instanceof Error || error instanceof AxiosError) &&
    error.message === '401 Forbidden'
  ) {
    return {
      type: 'not_available',
      message: 'This item is not available to rent. Please remove from your cart.',
    }
  }

  if (error instanceof Error) {
    return {
      type: 'error',
      message: `${error.message}: ${error.stack}`,
    }
  }

  return {
    type: 'error',
    message: 'Unknown error.',
  }
}

export function ItemResetErrorBoundary({
  id,
  error,
  resetErrorBoundary,
}: ItemResetErrorBoundaryProps) {
  const dispatch = useAppDispatch()
  const item = useAppSelector((state) => selectItemById(state, id))
  const list = useList(item?.listId)
  const { isPdp } = useAppContext()

  const handleRemove = () => {
    if (item) dispatch(itemRemoved(item))

    if (isPdp) {
      onTogglePdpCartButton({ id, listName: list?.name }, false)
    }
  }

  const errorMessage = resolveError(error)

  return (
    <div className="item-error-boundary twc-flex twc-items-start twc-gap-2 sm:twc-gap-4">
      <div className="item-error-boundary__media twc-grow twc-text-zinc-400 twc-bg-zinc-100 rounded-sm twc-basis-20 sm:twc-basis-28 twc-aspect-4/5 twc-flex twc-items-center twc-justify-center">
        <X size={36} />
      </div>

      <div className="item-error-boundary__content twc-gap-0.5 twc-grow-[999] twc-basis-0 twc-prose twc-prose-sm prose-pre:twc-bg-slate-200/75 prose-pre:twc-text-slate-800 prose-pre:twc-py-4 twc-p-4 twc-bg-zinc-100 twc-rounded">
        <h3 className="twc-text-sm twc-font-normal twc-text-zinc-500">
          (This prop could not be loaded)
        </h3>

        <p>
          <strong>An error occurred:</strong> {errorMessage.message}
        </p>

        {errorMessage.type == 'error' && <pre>Details: {JSON.stringify(item, null, 2)}</pre>}

        <div className="twc-flex twc-gap-2">
          <Button variant="outline" size="small" onClick={() => resetErrorBoundary()}>
            <span>Retry</span>
            <ArrowsCounterClockwise size={18} weight="bold" />
          </Button>

          <Button
            variant="outline"
            className="twc-border-rose-600 twc-text-rose-600 hover:twc-bg-rose-600 hover:twc-border-rose-600 hover:twc-text-white"
            onClick={handleRemove}
            size="small"
          >
            Remove <Trash />
          </Button>
        </div>
      </div>
    </div>
  )
}
