import { useCallback } from 'react'
import { TrashSimple } from '@phosphor-icons/react'

import { ListId, listRemoved } from '@/main/features/List/listSlice'

import { useAppDispatch } from '@/main/app/hooks/storeHooks'

import { Button } from '@/main/components/Button/Button'
import { Modal } from '@/main/components/Modal/Modal'

export function ListDeleteDialog({
  listId,
  listName,
  isOpen,
  onClose,
}: {
  listId?: ListId
  listName?: string
  isOpen: boolean
  onClose: (id?: ListId) => void
}) {
  const dispatch = useAppDispatch()

  const onDelete = useCallback(() => {
    if (!listId) return

    dispatch(listRemoved(listId))
    onClose('/')
  }, [dispatch, listId, onClose])

  const listNameText = listName ? `"${listName}"` : 'your cart'

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} onConfirm={onDelete}>
      <Modal.Title>Delete {listNameText}?</Modal.Title>
      <Modal.Description>
        {listNameText} will be deleted, including props and rental info.
      </Modal.Description>

      <Modal.Body>
        <p>
          To start a new request, go to{' '}
          <span className="twc-bg-sky-200 dark:twc-bg-sky-800 dark:twc-text-white twc-font-medium twc-px-1.5 twc-py-1 twc-ml-0.5 twc-text-sm twc-rounded">
            Options &gt; Create new project
          </span>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onClose()} size="large" variant="outline">
          Cancel
        </Button>

        <Button size="large" className="!twc-bg-rose-700 hover:!twc-bg-brand" onClick={onDelete}>
          Delete {listNameText} <TrashSimple />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
