import { useState, lazy, Suspense } from 'react'
import { CircleNotch, FileX } from '@phosphor-icons/react'

import { ListId } from '@/main/features/List/listSlice'

import { Button } from '@/main/components/Button/Button'
import { Modal } from '@/main/components/Modal/Modal'
import { Switch } from '@headlessui/react'

const ListPdfButton = lazy(() => import('./ListPdfButton'))

export function ListPdfDialog({
  listId,
  listName,
  isOpen,
  onClose,
}: {
  listId?: ListId
  listName?: string
  isOpen: boolean
  onClose: () => void
}) {
  const [includeDetails, setIncludeDetails] = useState(false)

  const listNameText = listName ? `"${listName}"` : 'your cart'

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Download PDF: {listNameText}?</Modal.Title>
      <Modal.Description>
        Download a PDF of props in your cart for {listNameText}.
      </Modal.Description>

      <Modal.Body>
        <Switch.Group>
          <div className="twc-flex twc-items-center twc-mt-6 twc-mb-1 twc-gap-2">
            <Switch
              checked={includeDetails}
              onChange={setIncludeDetails}
              className={`${
                includeDetails
                  ? '!twc-bg-emerald-600 dark:!twc-bg-emerald-600'
                  : '!twc-bg-gray-200 dark:!twc-bg-gray-600'
              } twc-relative twc-inline-flex twc-h-6 twc-w-11 twc-items-center twc-rounded-full twc-transition-colors focus:twc-outline-none focus:twc-ring-2 focus:twc-ring-indigo-500 focus:twc-ring-offset-2`}
            >
              <span
                className={`${
                  includeDetails ? 'twc-translate-x-6' : 'twc-translate-x-1'
                } twc-inline-block twc-h-4 twc-w-4 twc-transform twc-rounded-full twc-bg-white twc-transition-transform`}
              />
            </Switch>
            <Switch.Label className="twc-prose-base">Include project details?</Switch.Label>
          </div>

          <Switch.Description as="div" className="twc-not-prose">
            <p className="twc-text-xs twc-mt-2">
              Toggle &quot;on&quot; to include dates, client, reference, and contact name in the
              PDF.
            </p>
          </Switch.Description>
        </Switch.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} size="large" variant="outline">
          Cancel
        </Button>

        {listId ? (
          <Suspense
            fallback={
              <Button disabled>
                Download PDF <CircleNotch className="twc-animate-spin" />
              </Button>
            }
          >
            <ListPdfButton
              listId={listId}
              includeDetails={includeDetails}
              afterDownload={onClose}
            />
          </Suspense>
        ) : (
          <Button disabled>
            Download PDF <FileX />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
