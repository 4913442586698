interface ItemImageProps {
  media: AcmeProp['media']
  title: string
}

export function ItemImage({ media, title }: ItemImageProps) {
  return (
    <img
      className="twc-border twc-border-slate-100"
      src={media.small.source_url}
      alt={`Front view of ${title}`}
      width={media.small.width}
      height={media.small.height}
    />
  )
}
