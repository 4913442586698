import {
  getDay as getDayOfWeek,
  setDate as setDayOfMonth,
  addDays,
  lastDayOfMonth,
  addWeeks,
  getYear,
  isEqual,
  nextMonday,
  nextFriday,
  isWeekend,
  isTuesday,
  isThursday,
} from 'date-fns'

const getFirstOccurrence = (date: Date, dayOfWeekIndex: number) =>
  getNextOccurrence(setDayOfMonth(date, 1), dayOfWeekIndex)

const getNextOccurrence = (date: Date, dayOfWeekIndex: number) => {
  if (getDayOfWeek(date) === dayOfWeekIndex) return date
  if (getDayOfWeek(date) > dayOfWeekIndex)
    return addDays(date, 7 - getDayOfWeek(date) + dayOfWeekIndex)

  return addDays(date, dayOfWeekIndex - getDayOfWeek(date))
}

const getPrevOccurrence = (date: Date, dayOfWeekIndex: number) => {
  if (getDayOfWeek(date) === dayOfWeekIndex) return date
  if (getDayOfWeek(date) > dayOfWeekIndex)
    return addDays(date, -1 * (getDayOfWeek(date) - dayOfWeekIndex))

  return addDays(date, -1 * (getDayOfWeek(date) - 0 + (7 - dayOfWeekIndex)))
}

const getLastOfMonth = (date: Date, dayOfWeekIndex: number) =>
  getPrevOccurrence(lastDayOfMonth(date), dayOfWeekIndex)

const getEaster = (year: number) => {
  if (year < 325) throw new RangeError('Cannot calculate Easter dates before 325 AD.')

  const mod = (a: number, b: number) => a % b

  const div = (a: number, b: number) => {
    const q = a / b

    if (q < 0) throw new Error('Unexpected negative q')

    return Math.floor(q)
  }

  const y = year,
    skipMarchDays = 21,
    a = mod(y, 19),
    b = div(y, 100),
    c = mod(y, 100),
    d = div(b, 4),
    e = mod(b, 4),
    f = div(b + 8, 25),
    g = div(b - f + 1, 3),
    h = mod(19 * a + b - d - g + 15, 30),
    i = div(c, 4),
    k = mod(c, 4),
    l = mod(32 + 2 * e + 2 * i - h - k, 7),
    m = div(a + 11 * h + 22 * l, 451),
    t = h + l - 7 * m + skipMarchDays,
    n = div(t, 31) + 3,
    p = mod(t, 31)

  return new Date(year, n - 1, p + 1)
}

const getIndependenceDay = (year: number) => {
  const date = new Date(year, 6, 4)

  return isWeekend(date) ? nextMonday(date) : date
}

const getIndependenceDayIsThursday = (year: number) => {
  const date = new Date(year, 6, 4)

  return isThursday(date) ? new Date(year, 6, 5) : date
}

const getIndependenceDayIsTuesday = (year: number) => {
  const date = new Date(year, 6, 4)

  return isTuesday(date) ? new Date(year, 6, 3) : date
}

const getJuneteenth = (year: number) => {
  const date = new Date(year, 5, 19)

  return isWeekend(date) ? nextMonday(date) : date
}

const getLaborDay = (year: number) => getFirstOccurrence(new Date(year, 8), 1) // 1st Monday in September

const getIndigenousPeoplesDay = (year: number) =>
  addWeeks(getFirstOccurrence(new Date(year, 9), 1), 1) // 2nd Monday in October

const getThanksgiving = (year: number) => addWeeks(getFirstOccurrence(new Date(year, 10), 4), 3) // 3rd Thursday in November

const getBlackFriday = (year: number) => nextFriday(getThanksgiving(year)) // Friday after Thanksgiving

const getChristmasEve = (year: number) => new Date(year, 11, 24)

const getChristmasDay = (year: number) => new Date(year, 11, 25)

const getNewYearsEve = (year: number) => lastDayOfMonth(new Date(year, 11))

const getNewYearsDay = (year: number) => new Date(year, 0, 1)

// const getMartinLutherKingJrDay = (year: number) =>
//  addWeeks(getFirstOccurrence(new Date(year, 0), 1), 2); // 3rd Monday in January

const getMemorialDay = (year: number) => getLastOfMonth(new Date(year, 4), 1) // last Monday in May

const getLastDaysOfYear = (year: number) => [
  new Date(year, 11, 26),
  new Date(year, 11, 27),
  new Date(year, 11, 28),
  new Date(year, 11, 29),
  new Date(year, 11, 30),
]

const getHolidays = (year: number) => [
  getNewYearsDay(year),
  // getMartinLutherKingJrDay(year),
  getEaster(year),
  getMemorialDay(year),
  getJuneteenth(year),
  getIndependenceDay(year),
  getIndependenceDayIsTuesday(year),
  getIndependenceDayIsThursday(year),
  getLaborDay(year),
  getIndigenousPeoplesDay(year),
  getThanksgiving(year),
  getBlackFriday(year),
  getChristmasEve(year),
  getChristmasDay(year),
  getNewYearsEve(year),
  ...getLastDaysOfYear(year),
]

export const isHoliday = (date: Date) =>
  getHolidays(getYear(date)).some((holiday) => isEqual(holiday, date))
