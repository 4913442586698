import { EntityId } from '@reduxjs/toolkit'
// import * as yup from 'yup'
import { queryClient } from '../../../app/services/queryClient'
import { getItemById, replaceEntities, replaceRegEx } from './acmeProp'

import { z } from 'zod'

const Numeric = z
  .number()
  .positive()
  .or(z.preprocess((val) => parseInt(z.string().parse(val), 10), z.number().positive()))

const NumericInt = z
  .number()
  .positive()
  .int()
  .or(z.preprocess((val) => parseInt(z.string().parse(val), 10), z.number().positive().int()))

const AcmePropData = z.object({
  id: NumericInt,
  quantity: NumericInt,
  link: z.string(),
  slug: z.string(),
  status: z.string(),
  content: z.string().optional(),
  sku: z.string(),
  name: z.string(),
  rate: Numeric,
  inventory: NumericInt,
  is_set: z.string().optional(),
  thumbnail_url: z.string(),
  thumbnail_height: NumericInt,
  thumbnail_width: NumericInt,
  dimensions: z.string().optional(),
})

export type ItemFormData = z.infer<typeof AcmePropData>

const getItemData = (id: EntityId) => queryClient.getQueryData<AcmeProp>(['item', id])
export const fetchItemData = async (id: EntityId) =>
  queryClient.fetchQuery(['item', id], () => getItemById(id))

const setItemData = (id: EntityId, data: AcmeProp) => queryClient.setQueryData(['item', id], data)

const resolveAcmeProp = async (id: EntityId, formData: Partial<ItemFormData>) => {
  try {
    const validAcmeProp = AcmePropData.parse(formData)
    const parseName = validAcmeProp.name.replace(replaceRegEx, (match) => replaceEntities(match))
    const parseDimensions = validAcmeProp.dimensions
      ? validAcmeProp.dimensions.replace(replaceRegEx, (match) => replaceEntities(match))
      : undefined

    const acmeProp = {
      id,
      title: `${validAcmeProp.sku} ${parseName}`,
      slug: validAcmeProp.slug,
      link: validAcmeProp.link,
      status: validAcmeProp.status,
      content: validAcmeProp?.content,
      sku: validAcmeProp.sku,
      name: parseName,
      rate: validAcmeProp.rate,
      inventory: validAcmeProp.inventory,
      dimensions: parseDimensions,
      media: {
        small: {
          source_url: validAcmeProp.thumbnail_url,
          width: validAcmeProp.thumbnail_width,
          height: validAcmeProp.thumbnail_height,
          mime_type: 'jpeg',
        },
      },
      is_set: validAcmeProp.is_set === 'true',
    } as AcmeProp

    setItemData(id, acmeProp)

    return acmeProp
  } catch (e) {
    console.warn(e)

    return await fetchItemData(id)
  }
}

export const pdpAddToCart = async (id: number | string, formData: Partial<ItemFormData>) => {
  const validateId = parseInt(`${id}`, 10)
  const postId = `${validateId}`
  const count = parseInt(`${formData.quantity || 1}`, 10)

  const data = getItemData(postId)

  if (data) return { count, data }

  const acmeProp = await resolveAcmeProp(postId, formData)

  return { count, data: acmeProp }
}
