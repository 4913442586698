import { useState, useEffect, useRef } from 'react'

export function useTooltip() {
  let timeout: ReturnType<typeof setTimeout>

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleTooltip = (open: boolean) => {
    setIsOpen(!open)
    buttonRef?.current?.click()
  }

  const onHover = (open: boolean, action: 'onMouseEnter' | 'onMouseLeave') => {
    if (
      (!open && !isOpen && action === 'onMouseEnter') ||
      (open && isOpen && action === 'onMouseLeave')
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout)
      // open the modal after a timeout
      timeout = setTimeout(() => toggleTooltip(open), 300)
    }
  }

  const onClick = (open: boolean) => {
    setIsOpen(!open)
    clearTimeout(timeout)
  }

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Element | null

    if (buttonRef.current && !buttonRef.current.contains(target)) {
      if (target?.id !== 'acmebk-cart-drawer-overlay') {
        event.stopPropagation()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return { buttonRef, isOpen, onHover, onClick }
}
