import { useState, useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { formatISO } from 'date-fns/esm'

import { useAppSelector, useAppDispatch } from '@/main/app/hooks/storeHooks'
import { appIsNotReady, appIsIdle, appIsReady } from '@/main/app/store'
import { getItems } from '@/main/features/Item/services/acmeProp'
import { selectItemsByListId } from '@/main/features/Item/itemSlice'

import type { ListId } from '@/main/features/List/listSlice'

const init = formatISO(new Date())

export function useLoadItems(id?: ListId) {
  const [activeId, setActiveId] = useState<ListId | undefined>(init)
  const items = useAppSelector((state) => selectItemsByListId(state, id))
  const postIds = items.map((item) => item.postId)
  const idsRef = JSON.stringify(postIds)
  const dispatch = useAppDispatch()
  const client = useQueryClient()

  const loadItems = useCallback(async () => {
    dispatch(appIsNotReady())

    try {
      const serializeIds = JSON.parse(idsRef)

      if (serializeIds && serializeIds.length > 0) {
        const items = await getItems(serializeIds)

        items.forEach((item) => client.setQueryData(['item', `${item.id}`], item))
      }

      dispatch(appIsReady())
    } catch (err) {
      console.error(err)
      dispatch(appIsIdle())
    }
  }, [dispatch, client, idsRef])

  useEffect(() => {
    if (activeId !== id) {
      loadItems()
      setActiveId(id)
    }
  }, [loadItems, activeId, id])

  return { postIds, items }
}
