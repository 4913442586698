import { configureStore, combineReducers, createSlice } from '@reduxjs/toolkit'

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// import * as Sentry from "@sentry/react";

// ...

// const store = createStore(rootReducer, sentryReduxEnhancer);

import { syncTabs } from '@/main/app/utils/syncTabs'
import listReducer, { submitList } from '@/main/features/List/listSlice'
import itemReducer from '@/main/features/Item/itemSlice'

const version = 5

const initialAppState: { isReady: boolean | null; isReadyAt: number; successHash?: string } = {
  isReady: false,
  isReadyAt: 0,
}

const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    appIsReady: (state) => {
      state.isReady = true
      state.isReadyAt = Date.now()
    },
    appIsIdle: (state) => {
      state.isReady = null
      state.isReadyAt = -1
    },
    appIsNotReady: (state) => {
      state.isReady = false
      state.isReadyAt = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitList.fulfilled, (state, action) => {
      state.successHash = action.payload.hash
    })
  },
})

const rootReducers = combineReducers({
  lists: listReducer,
  items: itemReducer,
  app: appSlice.reducer,
})

const persistConfig = {
  key: 'root',
  blacklist: ['app'],
  storage,
  version,
}

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: import.meta.env.DEV,
  // enhancers: !import.meta.env.DEV ? [Sentry.createReduxEnhancer()] : undefined,
})

syncTabs(store, 'root')

export const { appIsReady, appIsIdle, appIsNotReady } = appSlice.actions

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
