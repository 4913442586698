import {
  parsePhoneNumber as libParsePhoneNumber,
  isPossiblePhoneNumber,
  MetadataJson,
  PhoneNumber,
} from 'libphonenumber-js/core'

import phoneMeta from '@/cart/utils/metadata.phone.json'

const parsePhoneNumber = (value: string): PhoneNumber | undefined => {
  try {
    const parsed = libParsePhoneNumber(value, phoneMeta as MetadataJson)

    return parsed
  } catch (err) {
    console.warn('Invalid phone number format', err)

    return undefined
  }
}

export function isMaybePhoneNumber(value: string): boolean {
  return isPossiblePhoneNumber(value, phoneMeta as MetadataJson)
}

export function formatPhoneNumber(value: string, raw = false): string | undefined {
  try {
    let phoneNumber: string | undefined = value

    if (!isMaybePhoneNumber(phoneNumber)) {
      phoneNumber = `+1${value}`
    }

    if (!isMaybePhoneNumber(phoneNumber)) {
      return undefined
    }

    const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

    return !parsedPhoneNumber
      ? undefined
      : raw
      ? parsedPhoneNumber.number
      : parsedPhoneNumber.formatNational()
  } catch (err) {
    console.error(err)

    return undefined
  }
}
