import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  PayloadAction,
  EntityId,
  EntityState,
  createDraftSafeSelector,
} from '@reduxjs/toolkit'

import type { RootState } from '@/main/app/store'
import { DEFAULT_LIST_ID, listEmpty, ListId, listRemoved } from '../List/listSlice'

import { fetchItemData, pdpAddToCart, ItemFormData } from './services/pdpAddToCart'

export type ItemId = EntityId

export interface Item {
  id: ItemId
  postId: EntityId
  listId: EntityId
  count: number
}

type MaybeItem = Omit<Item, 'count' | 'listId'> & Partial<Pick<Item, 'listId'>>

interface ItemWithDataAndHistory {
  next: Item
  previous?: Item
  data?: AcmeProp
  activeList?: ListId
}

const itemAdapter = createEntityAdapter<Item>()
const initialState = itemAdapter.getInitialState()

export const toggleItem = createAsyncThunk<
  ItemWithDataAndHistory, // return type
  MaybeItem & { formData: Partial<ItemFormData> }, // input type
  { state: RootState; rejectValue: unknown } // thunk api
>('items/toggleItem', async (item, { getState }) => {
  const state = getState()
  const { id, postId, listId, formData } = item

  const { count, data } = await pdpAddToCart(postId, formData)

  const resolvedListId = listId ?? DEFAULT_LIST_ID
  const resolvedItemId = listId === undefined ? `${DEFAULT_LIST_ID}_${postId}` : id

  const isActive =
    state.lists.entities[resolvedListId] &&
    state.items.entities[resolvedItemId]?.listId === state.lists.activeId &&
    listId === state.lists.activeId
  const previous = isActive ? state.items.entities[id] : undefined

  if (!isActive) {
    const listSize = state.lists.entities[resolvedListId]?.itemIds?.length ?? 0

    if (listSize > 199) {
      throw new Error('The max number of 200 props has been reached for this project')
    }
  }

  return {
    next: {
      id: resolvedItemId,
      listId: resolvedListId,
      postId,
      count,
    },
    previous,
    data,
    activeList: listId,
  }
})

export const addItem = createAsyncThunk('items/addItem', async (item: Item) => {
  const data = await fetchItemData(item.postId)

  return {
    item,
    data,
  }
})

export const removeItem = createAsyncThunk('items/removeItem', async (item: Item) => {
  const data = await fetchItemData(item.postId)

  return {
    item,
    data,
  }
})

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    itemUpdated: itemAdapter.updateOne,
    itemRemoveMany: itemAdapter.removeMany,
    itemAdded(state, action: PayloadAction<Item>) {
      itemAdapter.upsertOne(state, action)
    },
    itemRemoved(state, action: PayloadAction<Item>) {
      itemAdapter.removeOne(state, action.payload.id)
    },
    itemToggled(state, action: PayloadAction<Item>) {
      if (state.entities[action.payload.id]) {
        itemSlice.caseReducers.itemRemoved(state, action)
      } else {
        itemSlice.caseReducers.itemAdded(state, action)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleItem.fulfilled, (state, action) => {
        const {
          payload: { next, activeList },
        } = action

        if (activeList) {
          itemSlice.caseReducers.itemToggled(state, {
            payload: next,
            type: 'itemToggled',
          })
        } else {
          itemSlice.caseReducers.itemAdded(state, { payload: next, type: 'itemToggled' })
        }
      })
      .addCase(addItem.fulfilled, (state, action) => {
        itemSlice.caseReducers.itemAdded(state, {
          payload: { ...action.payload.item },
          type: 'itemAdded',
        })
      })
      .addCase(removeItem.fulfilled, (state, action) => {
        itemSlice.caseReducers.itemRemoved(state, {
          payload: { ...action.payload.item },
          type: 'itemRemoved',
        })
      })
      .addCase(listRemoved, (state, action) => {
        const listItems = state.ids.filter((id) => state.entities[id]?.listId === action.payload)

        itemSlice.caseReducers.itemRemoveMany(state, listItems)
      })
      .addCase(listEmpty, (state, action) => {
        if (action.payload) {
          const listItems = state.ids.filter((id) => state.entities[id]?.listId === action.payload)

          itemSlice.caseReducers.itemRemoveMany(state, listItems)
        }
      })
  },
})

export const { itemAdded, itemRemoved, itemUpdated } = itemSlice.actions

const { selectById: selectItemById } = itemAdapter.getSelectors((state: RootState) => state.items)

const selectItems = (state: RootState) => state.items
const selectItemEntities = (state: EntityState<Item>) => state.entities
const selectItemIds = (state: EntityState<Item>) => state.ids
const selectListId = (_: unknown, listId?: ListId) => listId

const selectAll = createDraftSafeSelector(selectItemIds, selectItemEntities, (ids, entities) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ids.map((id) => entities[id]!)
)

const selectAllItems = createDraftSafeSelector(selectItems, selectAll)

const selectItemsByListId = createDraftSafeSelector(
  selectAllItems,
  selectListId,
  (items, listId?) => items.filter((item) => item?.listId === listId)
)

// const selectPostIdsByListId = createDraftSafeSelector(selectItemsByListId, (items) =>
//   items.map((item) => item.postId)
// )

// export const selectTotalQuantity = createSelector(
//   (state: RootState) => selectAllItems(state),
//   (state: RootState) => state.lists.activeListId,
//   (items, activeListId) =>
//     items.reduce((total, item) => (item.listId === activeListId ? total + item.count : total), 0)
// )

export { selectItemById, selectItemsByListId }

export default itemSlice.reducer
