import type { EntityId } from '@reduxjs/toolkit'

import { ListClearDialog } from './ListClearDialog/ListClearDialog'
import { ListCreateDialog } from './ListCreateDialog/ListCreateDialog'
import { ListDeleteDialog } from './ListDeleteDialog/ListDeleteDialog'
import { ListRenameDialog } from './ListRenameDialog/ListRenameDialog'

import type { ListId } from '../../listSlice'
import { ListPdfDialog } from '../ListPdf/ListPdfDialog'
import { ListShareDialog } from '../ListShare/ListShareDialog'

export type DialogId = 'create' | 'rename' | 'delete' | 'reset' | 'clear' | 'pdf' | 'share'

interface ListDialogsProps {
  listId?: ListId
  listName?: string
  activeDialog?: DialogId
  onCloseDialog: (id?: EntityId) => void
}

export function ListDialogs({ listId, listName, activeDialog, onCloseDialog }: ListDialogsProps) {
  return (
    <>
      <ListClearDialog
        listId={listId}
        listName={listName}
        isOpen={activeDialog === 'clear'}
        onClose={onCloseDialog}
      />

      <ListRenameDialog
        listId={listId}
        listName={listName}
        isOpen={activeDialog === 'rename'}
        onClose={onCloseDialog}
      />

      <ListCreateDialog isOpen={activeDialog === 'create'} onClose={onCloseDialog} />

      <ListDeleteDialog
        listId={listId}
        listName={listName}
        isOpen={activeDialog === 'delete'}
        onClose={onCloseDialog}
      />

      <ListPdfDialog
        listId={listId}
        listName={listName}
        isOpen={activeDialog === 'pdf'}
        onClose={onCloseDialog}
      />

      <ListShareDialog
        listId={listId}
        listName={listName}
        isOpen={activeDialog === 'share'}
        onClose={onCloseDialog}
      />
    </>
  )
}
