import axios, { isAxiosError, AxiosError, AxiosResponse } from 'axios'
import { appSettings } from '@/main/app/global'

const ACMEBK_WP_NONCE = appSettings?.nonce
const ACMEBK_WP_NONCE_ACTION = appSettings?.action

const ACMEBK_API_FORM = new URL(
  appSettings?.formApi ??
    'https://acmebrooklyn.local/wp-json/contact-form-7/v1/contact-forms/40379/feedback'
)
const ACMEBK_FORM_ID = appSettings?.formId ?? 40379

const ACMEBK_API_SUBSCRIBE = new URL(
  appSettings?.subscribeApi ?? 'https://acmebrooklyn.local/wp-json/acmebk/v1/subscribe'
)

import type { Submission } from '@/main/app/schema'

import { createFormData } from '../utils/createFormData'
import { SubmissionPayload, SubmissionWithOptIn } from '@/main/features/List/listSlice'

export type InvalidField = {
  field: keyof Submission | 'items'
  message: string
  idref?: string
}

interface FormResponse {
  message: string
}

interface FormInvalid extends FormResponse {
  status: 'validation_failed'
  invalid_fields: InvalidField[]
}

interface FormSuccess extends FormResponse {
  status: 'mail_sent'
  posted_data_hash: string
  requestId?: string
  recordId?: string
  optIn?: AxiosError | AxiosResponse | null
}

type FormResult = FormSuccess | FormInvalid

export class ServerValidationError {
  constructor(
    public readonly name: 'invalid',
    public readonly message: string,
    public readonly values: FormInvalid['invalid_fields']
  ) {
    this.name = 'invalid'
    this.message = message
    this.values = values
  }
}

const formApi = axios.create({
  baseURL: ACMEBK_API_FORM.pathname,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json, */*;q=0.1',
    // 'X-WP-Nonce': ACMEBK_WP_NONCE,
  },
  transformRequest: [
    (payload: SubmissionWithOptIn) => createFormData(payload),
    (data: FormData) => {
      data.append('id', `${ACMEBK_FORM_ID}`)
      data.append('_wpcf7_unit_tag', `wpcf7-f${ACMEBK_FORM_ID}-p68058-o1`)
      return data
    },
  ],
})

const maybeSubscribe = async (payload: SubmissionPayload) => {
  if (!payload.optIn) return 'no'
  if (payload.values.optional.suggestion && payload.values.optional.suggestion !== '')
    return payload.optIn ? 'yes' : 'no'

  const fd = new FormData()

  fd.append('email', payload.values.profile.email)
  fd.append('firstName', payload.values.profile.firstName)
  fd.append('lastName', payload.values.profile.lastName)
  fd.append('company', payload.values.billing.client)
  fd.append('acmebk_subscribe', ACMEBK_WP_NONCE_ACTION)

  try {
    const signup = await axios.post(ACMEBK_API_SUBSCRIBE.pathname, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json, */*;q=0.1',
        'X-WP-Nonce': ACMEBK_WP_NONCE,
      },
    })

    return signup.statusText === 'OK' || signup.status === 200 ? 'yes' : 'incomplete'
  } catch (err) {
    if (isAnAxiosError(err)) {
      console.warn(err.message)
    } else {
      console.warn('A problem occurred during sign-up.')
    }

    return 'incomplete'
  }
}

export function isAnAxiosError(error: unknown): error is AxiosError {
  return isAxiosError(error)
}

export async function submitForm(payload: SubmissionPayload) {
  const optIn = await maybeSubscribe(payload)

  return await formApi.post<FormResult>('', { ...payload, optIn })
}
