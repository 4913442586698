import axios, { AxiosError } from 'axios'
import { appSettings } from '@/main/app/global'

const ACMEBK_API_PROPS = new URL(appSettings?.propApi)

const MAX_REQUESTS = 5
const INTERVAL_MS = 10

let PENDING_REQUESTS = 0

const acmeApi = axios.create({
  baseURL: ACMEBK_API_PROPS.pathname,
})

// https://gist.github.com/matthewsuan/2bdc9e7f459d5b073d58d1ebc0613169

acmeApi.interceptors.request.use(function (config) {
  return new Promise((resolve, _reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

acmeApi.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function (error: AxiosError) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    if (error?.message === 'Network Error') {
      return Promise.reject(new Error(error.message, { cause: error }))
    }

    const status = error?.response?.status
    // const data = error?.response?.data || error?.message

    if (status === 401) {
      return Promise.reject(new Error('401 Forbidden', { cause: error }))
    }

    return Promise.reject(error)
  }
)

export default acmeApi
