import { useEffect, useCallback } from 'react'
import toast, { useToaster } from 'react-hot-toast/headless'
import type { SerializedError } from '@reduxjs/toolkit'

import { selectActiveList } from '@/main/features/List/listSlice'
import type { ItemFormData } from '@/main/features/Item/services/pdpAddToCart'

import {
  onTogglePdpCartButton,
  setAddToCartButtonStatus,
  toggleAllPdpCartButtons,
} from '@/main/app/utils/pdpButtons'

import { useAppDispatch, useAppSelector } from '@/main/app/hooks/storeHooks'
import { addItem, Item, toggleItem } from '@/main/features/Item/itemSlice'

import { onLoadingToast, onAddedToast, onRemovedToast, onErrorToast } from './onEventsToast'
import { EventsToast } from './EventsToast'
import { replaceEntities, replaceRegEx } from '@/main/features/Item/services/acmeProp'
import { useAppContext } from '@/main/app/context'
import { useLoadItems } from '@/main/features/List/hooks/useLoadItems'
import { useNavigate } from 'react-router-dom'

function isError(err: unknown): err is SerializedError | Error {
  if (!err) return false
  if (typeof err !== 'object') return false

  if ('message' in err && 'name' in err && 'stack' in err) {
    return true
  } else {
    return false
  }
}

function Events() {
  const { toasts, handlers } = useToaster({
    duration: 3000,
  })
  const { startPause, endPause, calculateOffset, updateHeight } = handlers

  const dispatch = useAppDispatch()
  const isReady = useAppSelector((state) => state.app.isReady)
  const list = useAppSelector(selectActiveList)

  const navigate = useNavigate()

  const listId = list?.id
  const listName = list?.name

  const { isPdp } = useAppContext()
  const { postIds } = useLoadItems(listId)

  const onUndo = useCallback(
    async (previousItem: Item) => {
      setAddToCartButtonStatus(previousItem.postId, 'pending')

      try {
        const result = await dispatch(addItem(previousItem)).unwrap()

        onTogglePdpCartButton({ id: previousItem.postId, listName }, true)

        return result
      } catch (err) {
        console.error(err)
        setAddToCartButtonStatus(previousItem.postId, 'idle')
      }
    },
    [dispatch, listName]
  )

  const onToggle = useCallback(
    async (inputId: string | number, formData: Partial<ItemFormData>) => {
      const id = `${listId}_${inputId}`
      const postId = `${inputId}`

      setAddToCartButtonStatus(postId, 'pending')

      onLoadingToast(id, {
        message: `Updating ${formData?.sku ?? 'a'} ${formData?.name ?? 'prop'} in ${
          listName ?? 'cart'
        }`,
      })

      try {
        const { data, next, previous } = await dispatch(
          toggleItem({ id, postId, listId, formData })
        ).unwrap()

        const isAdded = previous === undefined

        if (isAdded) {
          onAddedToast(id, {
            listName,
            item: next,
            data,
            handleOpen: () => navigate(`/cart/${next.listId}`),
          })
        } else {
          onRemovedToast(id, {
            listName,
            item: previous,
            data,
            handleUndo: () => onUndo(previous),
          })
        }

        onTogglePdpCartButton({ id: postId, listName }, isAdded)
      } catch (err) {
        let errMsg = 'Unknown error'
        const itemName =
          formData?.name?.replace(replaceRegEx, (match) => replaceEntities(match)) ?? 'prop'

        if (isError(err)) {
          if (err.message) {
            errMsg = err.message
          } else if (err.name) {
            errMsg = err.name
          }
        }

        console.error(err)

        onErrorToast(id, {
          message: `Error updating ${formData?.sku ?? 'a'} ${itemName} in ${
            listName ?? 'cart'
          }: ${errMsg}`,
        })
      } finally {
        setAddToCartButtonStatus(postId, 'idle')
      }
    },
    [dispatch, listId, listName, onUndo, navigate]
  )

  const handleSubmitPdp = useCallback(
    async (e: Event) => {
      const form = e.target as HTMLFormElement

      if (form.dataset.addToCartForm === undefined) return

      e.preventDefault()

      try {
        const formData: Partial<ItemFormData> = Object.fromEntries(new FormData(form))

        if (!formData.id) throw new Error('Post ID is missing')

        onToggle(`${formData.id}`, formData)
      } catch (err) {
        console.error(err)

        if (isError(err)) {
          onErrorToast('error', {
            message: err.message || err.name || 'Unknown error (see console)',
          })
        } else {
          onErrorToast('error', { message: 'Unknown error (see console)' })
        }
      }
    },
    [onToggle]
  )

  useEffect(() => {
    if (isPdp) {
      toggleAllPdpCartButtons({ ids: postIds ?? [], listName }, !isReady)
    }
  }, [isPdp, postIds, listName, isReady])

  useEffect(() => {
    if (isPdp) {
      window.addEventListener('submit', handleSubmitPdp)
    }

    return () => {
      if (isPdp) {
        window.removeEventListener('submit', handleSubmitPdp)
      }
    }
  }, [isPdp, handleSubmitPdp])

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i > 4)
      .forEach((t) => toast.dismiss(t.id))
  }, [toasts])

  return (
    <div
      id="acmebk-toast-events"
      className="twc-fixed twc-inset-8 twc-pointer-events-none twc-z-[999]"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((t) => (
        <EventsToast
          key={t.id}
          t={t}
          calculateOffset={calculateOffset}
          updateHeight={updateHeight}
          onDismiss={() => toast.dismiss(t.id)}
        />
      ))}
    </div>
  )
}

export { Events }
