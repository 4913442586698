import { useLayoutEffect, useRef } from 'react'
import { X } from '@phosphor-icons/react'
import type { Toast, useToaster } from 'react-hot-toast'

type ToastHandlers = ReturnType<typeof useToaster>['handlers']

interface NotificationProps {
  calculateOffset: ToastHandlers['calculateOffset']
  updateHeight: ToastHandlers['updateHeight']
  t: Toast
  onDismiss: () => void
}

export function EventsToast({ t, calculateOffset, updateHeight, onDismiss }: NotificationProps) {
  const ref = useRef<HTMLDivElement>(null)

  const offset = calculateOffset(t, {
    reverseOrder: false,
    gutter: 48,
    defaultPosition: 'bottom-left',
  })

  const className = [
    'twc-max-w-xs lg:twc-max-w-sm twc-rounded twc-py-3 twc-pl-2 twc-pr-3 lg:twc-p-4 motion-safe:twc-transition-all twc-flex twc-items-start twc-gap-1 twc-mb-4',
    t.visible ? 'twc-opacity-100' : 'twc-opacity-0',
    t.className,
  ]
    .filter(Boolean)
    .join(' ')

  useLayoutEffect(() => {
    if (ref.current && !t.height) {
      const height = ref.current.getBoundingClientRect().height
      updateHeight(t.id, height)
    }
  }, [t, updateHeight, ref])

  return (
    <div
      ref={ref}
      className={className}
      style={{
        transform: `translate3d(0, -${offset}px, 0)`,
      }}
      {...t.ariaProps}
    >
      <div className="twc-relative twc-max-w twc-top-px" aria-hidden="true">
        {t.icon}
      </div>

      <div className="twc-leading-snug twc-text-sm lg:twc-text-base lg:twc-leading-normal twc-pointer-events-auto">
        {t.message as React.ReactNode}
      </div>

      <button className="twc-pointer-events-auto" onClick={() => onDismiss()}>
        <span className="twc-sr-only">Dismiss</span>
        <X size={18} />
      </button>
    </div>
  )
}
