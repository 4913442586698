import { useCallback } from 'react'
import { TrashSimple } from '@phosphor-icons/react'

import { ListId, listEmpty } from '@/main/features/List/listSlice'

import { Button } from '@/main/components/Button/Button'
import { Modal } from '@/main/components/Modal/Modal'
import { useAppDispatch } from '@/main/app/hooks/storeHooks'

export function ListClearDialog({
  listId,
  listName = 'Project',
  isOpen,
  onClose,
}: {
  listId?: ListId
  listName?: string
  isOpen: boolean
  onClose: () => void
}) {
  // const cancelRef = useRef(null)
  const dispatch = useAppDispatch()

  const onClearProject = useCallback(() => {
    dispatch(listEmpty(listId))
    onClose()
  }, [dispatch, listId, onClose])

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} onConfirm={onClearProject}>
      <Modal.Title>Remove all props in &quot;{listName}&quot;?</Modal.Title>
      <Modal.Description>All items in &quot;{listName}&quot; will be removed.</Modal.Description>

      <Modal.Body>
        <p>Done with this project? You can delete {listName} instead and Create A New Project</p>

        <p>
          To start a new request, go to{' '}
          <span className="twc-bg-sky-200 dark:twc-bg-sky-800 dark:twc-text-white twc-font-medium twc-px-1.5 twc-py-1 twc-ml-0.5 twc-text-sm twc-rounded">
            Options &gt; Create new project
          </span>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} size="large" variant="outline">
          Cancel
        </Button>

        <Button size="large" className="twc-bg-rose-700" onClick={onClearProject}>
          Empty/Clear &quot;{listName}&quot; <TrashSimple />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
