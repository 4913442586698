import {
  createEntityAdapter,
  createSlice,
  createSelector,
  createAsyncThunk,
  nanoid,
  PayloadAction,
  EntityId,
  EntityState,
  Dictionary,
} from '@reduxjs/toolkit'
import { formatISO } from 'date-fns/esm'
import type { AxiosResponse } from 'axios'

import { RootState } from '@/main/app/store'

import {
  addItem,
  removeItem,
  toggleItem,
  itemAdded,
  itemRemoved,
  Item,
  ItemId,
} from '@/main/features/Item/itemSlice'

import {
  InvalidField,
  isAnAxiosError,
  ServerValidationError,
  submitForm,
} from '@/cart/services/submitForm'

import type {
  TProfile,
  TBilling,
  TDates,
  TShipping,
  ProfileAndBilling,
  DatesAndShipping,
  Submission,
} from '@/main/app/schema'
import { localStorageUsage } from '@/main/app/utils/localStorageUsage'

type SubmissionValue<T = unknown> = {
  status: 'initial' | 'saved'
  values: Partial<T>
}

export interface FormState {
  profile?: SubmissionValue<TProfile>
  billing?: SubmissionValue<TBilling>
  dates?: SubmissionValue<TDates>
  shipping?: SubmissionValue<TShipping>
}

export type ItemWithData = Omit<Item, 'id'> & AcmeProp
export type SubmissionResult = Submission & { items: ItemWithData[]; sent: string }

export type ListId = EntityId

export interface List {
  id: ListId
  name: string
  createdAt: string
  itemIds: ItemId[]
  form?: FormState
  history?: SubmissionResult
  recordId?: string
  requestId?: string
}

type Lists = EntityState<List> & {
  activeId: ListId | undefined
}

type FormHistory = {
  lastStart: false | string
  lastEnd: false | string
  add: ItemWithData[]
  remove: ItemWithData[]
  keep: ItemWithData[]
  change: (ItemWithData & { change: number })[]
}

type OptIn = 'yes' | 'no' | 'incomplete'

export interface SubmissionPayload {
  values: Submission
  data: ItemWithData[]
  history?: FormHistory
  recordId?: string
  requestId?: string
  optIn: boolean
}

export interface SubmissionWithOptIn extends Omit<SubmissionPayload, 'optIn'> {
  optIn: OptIn
}

interface RejectedPayload {
  message: string
}

interface RejectedInvalidPayload extends RejectedPayload {
  name: 'invalid'
  errors: InvalidField[]
}

interface RejectedAxiosResponsePayload extends RejectedPayload {
  name: 'response'
  data: AxiosResponse['data']
  status: number
}

interface RejectedAxiosErrorPayload extends RejectedPayload {
  name: 'request' | 'axios'
}

interface RejectedUnknownPayload extends RejectedPayload {
  name: 'unknown'
  detail: unknown
}

export type Rejected =
  | RejectedInvalidPayload
  | RejectedAxiosResponsePayload
  | RejectedAxiosErrorPayload
  | RejectedUnknownPayload

export const DEFAULT_LIST_ID = 'main-project'

const DEFAULT_LIST_NAME = 'My Project'

const resolveOptIn = (data: unknown) => {
  if (data instanceof FormData) {
    return data.get('optIn') as OptIn
  }

  return 'no'
}

export const submitList = createAsyncThunk<
  SubmissionResult & {
    listId: ListId
    optIn: OptIn
    hash: string
    recordId?: string
    requestId?: string
  },
  SubmissionPayload & { listId: ListId; optIn: boolean; recordId?: string },
  { rejectValue: Rejected }
>('list/submitList', async ({ listId, ...payload }, { rejectWithValue }) => {
  try {
    const results = await submitForm(payload)

    if (results.data.status === 'validation_failed') {
      throw new ServerValidationError('invalid', results.data.message, results.data.invalid_fields)
    }

    if (results.data.message === 'Thank you for your message. It has been sent. ') {
      return {
        ...payload.values,
        items: payload.data,
        sent: formatISO(new Date()),
        hash: results.data.posted_data_hash,
        optIn: payload?.optIn ? 'yes' : 'no',
        requestId: results.data.requestId,
        listId,
      }
    }

    const optIn = resolveOptIn(results?.config?.data)

    return {
      ...payload.values,
      items: payload.data,
      sent: formatISO(new Date()),
      hash: results.data.posted_data_hash,
      // recordId: results.data.recordId,
      requestId: results.data.requestId,
      optIn,
      listId,
    }
  } catch (err: unknown) {
    if (err instanceof ServerValidationError) {
      return rejectWithValue({ name: err.name, message: err.message, errors: err.values })
    }

    if (isAnAxiosError(err)) {
      const message = err.message

      console.error(err)

      if (err.response) {
        return rejectWithValue({
          name: 'response',
          message,
          data: err.response.data,
          status: err.response.status,
        })
      } else if (err.request) {
        return rejectWithValue({ name: 'request', message })
      } else {
        return rejectWithValue({ name: 'axios', message })
      }
    }

    return rejectWithValue({ name: 'unknown', message: 'An unknown error occurred', detail: err })
  }
})

export const addList = createAsyncThunk<
  List,
  { name?: string; itemIds?: ItemId[] },
  { state: RootState }
>('list/addList', ({ name = DEFAULT_LIST_NAME, itemIds = [] }) => {
  const availableStorage = localStorageUsage()

  if (availableStorage < 0) {
    throw new Error(
      'There is not enough space to create a new project. Please delete one or more projects and try again.'
    )
  }

  const id = nanoid()
  const createdAt = formatISO(new Date())

  return { id, name, createdAt, itemIds }
})

const listAdapter = createEntityAdapter<List>()

const listState: { activeId: ListId | undefined; defaultProfile: TProfile | undefined } = {
  activeId: undefined,
  defaultProfile: undefined,
}

const initialState = listAdapter.getInitialState(listState)

const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    listRemoved(state, action: PayloadAction<ListId>) {
      const listToRemove = state.entities[action.payload]
      const activeId = state.activeId

      listAdapter.removeOne(state, action.payload)

      if (listToRemove?.id === activeId) {
        state.activeId = undefined
      }
    },
    listRenamed(state, action: PayloadAction<Pick<List, 'id' | 'name'>>) {
      listAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          name: action.payload.name,
        },
      })
    },
    listActive(state, action: PayloadAction<ListId | undefined>) {
      if (action.payload && state.entities[action.payload]) {
        state.activeId = action.payload
      } else {
        state.activeId = undefined
      }
    },
    listEmpty(state, action: PayloadAction<ListId | undefined>) {
      if (action.payload) {
        listAdapter.updateOne(state, {
          id: action.payload,
          changes: {
            itemIds: [],
          },
        })
      }
    },
    listReset(state, action: PayloadAction<{ listId: ListId; resetProfile: boolean }>) {
      const listToReset = state.entities[action.payload.listId]

      if (listToReset && listToReset.form) {
        const initialValue: SubmissionValue = { status: 'initial', values: {} }

        listToReset.history = undefined

        listToReset.form.billing = initialValue
        listToReset.form.dates = initialValue
        listToReset.form.shipping = initialValue

        if (action.payload.resetProfile) {
          listToReset.form.profile = initialValue
        }
      }
    },
    listItemAdded(state, action: PayloadAction<Item>) {
      const list = state.entities[action.payload.listId]

      if (list) {
        const itemIds = [...new Set([...list.itemIds, action.payload.id])]

        listAdapter.updateOne(state, {
          id: list.id,
          changes: { itemIds },
        })
      } else {
        listAdapter.upsertOne(state, {
          id: action.payload.listId,
          name: DEFAULT_LIST_NAME,
          createdAt: formatISO(new Date()),
          itemIds: [action.payload.id],
        })
      }

      if (state.activeId !== action.payload.listId && state.entities[action.payload.listId]) {
        state.activeId = action.payload.listId
      }
    },
    listItemRemoved(state, action: PayloadAction<Item>) {
      const list = state.entities[action.payload.listId]

      if (list) {
        const itemIds = list.itemIds.filter((x) => x !== action.payload.id)

        listAdapter.updateOne(state, {
          id: list.id,
          changes: { itemIds },
        })
      }
    },
    listFormSaved: {
      reducer(state, action: PayloadAction<{ id: ListId; form: Partial<FormState> }>) {
        const list = state.entities[action.payload.id]

        if (list) {
          const form = {
            ...list.form,
            ...action.payload.form,
          }

          list.form = form
        }
      },
      prepare(id: ListId, values: Partial<ProfileAndBilling & DatesAndShipping>) {
        const form: Partial<FormState> = {}

        if (values.profile) {
          form.profile = {
            status: 'saved',
            values: values.profile,
          }
        }

        if (values.billing) {
          form.billing = {
            status: 'saved',
            values: values.billing,
          }
        }

        if (values.dates) {
          form.dates = {
            status: 'saved',
            values: values.dates,
          }
        }

        if (values.shipping) {
          form.shipping = {
            status: 'saved',
            values: values.shipping,
          }
        }

        return {
          payload: {
            id,
            form,
          },
        }
      },
    },
    defaultProfileSaved(state, action: PayloadAction<TProfile>) {
      state.defaultProfile = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addList.fulfilled, (state, action) => {
        listAdapter.addOne(state, action.payload)
        state.activeId = action.payload.id
      })
      .addCase(submitList.fulfilled, (state, action) => {
        const {
          payload: { listId, hash: _hash, requestId, ...submission },
        } = action

        const changes: Partial<List> = {
          history: submission,
        }

        if (requestId) {
          changes.requestId = requestId
        }

        listAdapter.updateOne(state, { id: listId, changes })
      })
      .addCase(toggleItem.fulfilled, (state, action) => {
        const {
          payload: { next: item, previous, activeList },
        } = action

        const inList = previous !== undefined
        const payload = { ...item }
        const isActive = activeList !== undefined

        if (inList) {
          listSlice.caseReducers.listItemRemoved(state, { payload, type: 'listItemRemoved' })
        } else {
          listSlice.caseReducers.listItemAdded(state, { payload, type: 'listItemAdded' })
        }

        if (!isActive) {
          state.activeId = item.listId
        }
      })
      .addCase(addItem.fulfilled, (state, action) => {
        listSlice.caseReducers.listItemAdded(state, {
          payload: { ...action.payload.item },
          type: 'listItemAdded',
        })
      })
      .addCase(removeItem.fulfilled, (state, action) => {
        listSlice.caseReducers.listItemRemoved(state, {
          payload: { ...action.payload.item },
          type: 'listItemRemoved',
        })
      })
      .addCase(itemAdded, (state, action) => {
        listSlice.caseReducers.listItemAdded(state, action)
      })
      .addCase(itemRemoved, (state, action) => {
        listSlice.caseReducers.listItemRemoved(state, action)
      })
    // .addMatcher(
    //   (action) => action?.type !== undefined,
    //   (state) => {
    //     const activeId = state.activeId
    //     const activeList = activeId ? state.entities[activeId] : false

    //     if (activeId && activeList && activeList.requestId === undefined) {
    //       listAdapter.updateOne(state, {
    //         id: activeId,
    //         changes: {
    //           requestId: makeRequestId(),
    //         },
    //       })
    //     }
    //   }
    // )
  },
})

export const {
  listRemoved,
  listRenamed,
  listActive,
  listEmpty,
  listReset,
  listFormSaved,
  defaultProfileSaved,
} = listSlice.actions

const {
  selectAll: selectLists,
  selectEntities: selectListEntities,
  selectById: selectListById,
} = listAdapter.getSelectors((state: RootState) => state.lists)

const selectListState = (state: RootState) => state.lists
const selectActiveId = (lists: Lists) => lists.activeId
const selectById = (entities: Dictionary<List>, id: ListId | undefined) =>
  id ? entities[id] : undefined

const selectActiveListId = createSelector(selectListState, selectActiveId)
const selectActiveList = createSelector(selectListEntities, selectActiveListId, selectById)

export { selectLists, selectActiveList, selectActiveListId, selectListById }

export default listSlice.reducer
