function localStorageSize() {
  let lsTotal = 0,
    _xLen,
    _x
  for (_x in localStorage) {
    if (!Object.prototype.hasOwnProperty.call(localStorage, _x)) continue
    _xLen = (localStorage[_x].length + _x.length) * 2
    lsTotal += _xLen
  }
  return lsTotal
}

export function localStorageUsage() {
  const quota = 4194304 // use 4MB as a safe limit, which should be 1MB less than most quotas
  const size = localStorageSize()

  return quota - size
}
