import { useCallback, useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CircleWavy, CircleNotch } from '@phosphor-icons/react'

import { selectItemsByListId } from '@/main/features/Item/itemSlice'
import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { ListId, selectActiveListId } from '@/main/features/List/listSlice'
import { useNavigate } from 'react-router-dom'

interface PortalProps {
  portalRoot: HTMLElement
  children: React.ReactNode
}

interface AppButtonProps {
  isInactive?: boolean
}

function Portal({ portalRoot, children }: PortalProps) {
  return createPortal(children, portalRoot)
}

function AppButtonIcon({ listId }: { listId?: ListId }) {
  const items = useAppSelector((state) => selectItemsByListId(state, listId))
  const count = items.reduce((sum, item) => item.count + sum, 0)

  return (
    <div className="group-disabled:twc-text-slate-600 dark:group-disabled:twc-text-slate-300 twc-text-brand twc-relative twc-inline-flex twc-items-center twc-justify-center twc-self-center twc-w-full twc-h-full group-hover:twc-text-white motion-safe:twc-transition-all">
      <CircleWavy
        width="100%"
        height="100%"
        weight="duotone"
        style={{ position: 'absolute' }}
        aria-hidden="true"
      />
      <span className="twc-text-sm twc-inline-block twc-w-full -twc-indent-[0.5px] sm:twc-text-base twc-text-black dark:twc-text-white twc-no-underline group-hover:twc-text-white group-disabled:twc-text-slate-600 dark:group-disabled:twc-text-slate-300">
        {count}
      </span>{' '}
      <span className="twc-no-underline twc-sr-only">props in cart</span>
    </div>
  )
}

const buttonClasses = [
  'twc-group',
  'twc-rounded-lg',
  'twc-px-1',
  'twc-py-0.5',
  'twc-inline-flex',
  'twc-gap-0.5',
  'twc-items-center',
  'hover:twc-bg-primary',
  'hover:twc-text-white',
  'dark:twc-text-slate-50',
  'disabled:hover:twc-bg-slate-300',
  'dark:disabled:hover:twc-bg-slate-800',
  'disabled:twc-bg-transparent',
  'dark:disabled:twc-bg-transparent',
  'lg:twc-px-2',
  'lg:twc-py-1',
  'lg:twc-gap-1',
  'lg:twc-rounded-xl',
].join(' ')

export function AppButton({ isInactive = false }: AppButtonProps) {
  const btnRoot = document.getElementById('acmebk-cart-app-button-root')
  const container = useRef(btnRoot)

  const isReady = useAppSelector((state) => state.app.isReady)
  const listId = useAppSelector(selectActiveListId)
  const navigate = useNavigate()

  const onClickAppButton = useCallback(() => {
    navigate(`cart/${listId ?? ''}`)
  }, [listId, navigate])

  useLayoutEffect(() => {
    if (!container.current) return

    const loadingEl = container.current.querySelector('button[data-loading]')

    if (loadingEl) loadingEl.parentElement?.removeChild(loadingEl)
  }, [container])

  if (!container.current) return null

  return (
    <Portal portalRoot={container.current}>
      <button
        type="button"
        disabled={isInactive || !isReady}
        onClick={onClickAppButton}
        className={buttonClasses}
      >
        <span className="twc-text-xs xs:twc-text-sm twc-h sm:twc-text-base lg:twc-text-lg twc-leading-none lg:twc-underline twc-underline-offset-4 group-hover:twc-no-underline group-disabled:twc-no-underline group-disabled:twc-text-slate-600 dark:group-disabled:twc-text-slate-300">
          Cart
        </span>

        <div className="twc-h-8 twc-w-8 lg:twc-h-10 lg:twc-w-10">
          {!isReady ? (
            <CircleNotch className="twc-h-full twc-w-full twc-animate-spin" size={20} />
          ) : (
            <AppButtonIcon listId={listId} />
          )}
        </div>
      </button>
    </Portal>
  )
}
