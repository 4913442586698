import { useCallback, useState, lazy, Suspense } from 'react'
import { CircleNotch, Link } from '@phosphor-icons/react'

import { ListId } from '@/main/features/List/listSlice'

import { Button } from '@/main/components/Button/Button'
import { Modal } from '@/main/components/Modal/Modal'
import { Switch } from '@headlessui/react'

const ListShareButton = lazy(() => import('./ListShareButton'))

export function ListShareDialog({
  listId,
  listName,
  isOpen,
  onClose,
}: {
  listId?: ListId
  listName?: string
  isOpen: boolean
  onClose: () => void
}) {
  const [includeDetails, setIncludeDetails] = useState(false)
  const [shareStatus, setShareStatus] = useState<'loading' | 'idle' | 'success' | 'error'>()

  const listNameText = listName ? `"${listName}"` : 'your cart'

  const handleClose = useCallback(() => {
    setShareStatus(undefined)
    onClose()
  }, [onClose])

  return (
    <Modal isOpen={isOpen} onClose={() => shareStatus !== 'loading' && onClose()}>
      <Modal.Title>Copy link: {listNameText}?</Modal.Title>
      <Modal.Description>
        Copy a sharable, public link to the props in your cart for {listNameText}.
      </Modal.Description>

      <Modal.Body>
        <Switch.Group>
          <div className="twc-flex twc-items-center twc-mt-6 twc-mb-1 twc-gap-2">
            <Switch
              checked={includeDetails}
              onChange={setIncludeDetails}
              className={`${
                includeDetails
                  ? '!twc-bg-emerald-600 dark:!twc-bg-emerald-600'
                  : '!twc-bg-gray-200 dark:!twc-bg-gray-600'
              } twc-relative twc-inline-flex twc-h-6 twc-w-11 twc-items-center twc-rounded-full twc-transition-colors focus:twc-outline-none focus:twc-ring-2 focus:twc-ring-indigo-500 focus:twc-ring-offset-2`}
            >
              <span
                className={`${
                  includeDetails ? 'twc-translate-x-6' : 'twc-translate-x-1'
                } twc-inline-block twc-h-4 twc-w-4 twc-transform twc-rounded-full twc-bg-white twc-transition-transform`}
              />
            </Switch>
            <Switch.Label className="twc-prose-base">Include project details?</Switch.Label>
          </div>

          <Switch.Description as="div" className="twc-not-prose">
            <p className="twc-text-xs twc-mt-2">
              Toggle &quot;on&quot; to include dates, client, reference, and contact name in the
              link.
            </p>
          </Switch.Description>
        </Switch.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} size="large" variant="outline">
          Cancel
        </Button>

        {listId ? (
          <Suspense
            fallback={
              <Button type="button" disabled>
                Copy link <CircleNotch className="twc-animate-spin" />
              </Button>
            }
          >
            <ListShareButton
              listId={listId}
              setShareStatus={setShareStatus}
              shareStatus={shareStatus}
              includeDetails={includeDetails}
              afterShare={handleClose}
            />
          </Suspense>
        ) : (
          <Button type="button" disabled>
            Copy link <Link />
          </Button>
        )}

        {shareStatus === 'success' && (
          <small className="twc-absolute twc-text-zinc-500 twc-right-8 twc-bottom-1.5">
            This dialog will automatically close
          </small>
        )}
      </Modal.Footer>
    </Modal>
  )
}
