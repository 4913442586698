const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

// adapted from nanoid
const generateId = (alpha: string, size = 6) => {
  let id = ''
  let i = size

  while (i--) {
    id += alpha[(Math.random() * alpha.length) | 0]
  }

  return id
}

export const makeRequestId = () => {
  const date = new Date()

  const id = generateId(alphabet)

  const year = `${date.getFullYear()}`.slice(-2)
  const month = date.getMonth() + 1
  const day = date.getDate()

  const seconds = `00${date.getSeconds()}`.slice(-2)
  const ms = `000${date.getMilliseconds()}`.slice(-3)

  return `Q-${year}${month}${day}-${id}-${seconds}${ms}`
}
