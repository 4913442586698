import { createContext, useContext, Context } from 'react'
import { appSettings } from '@/main/app/global'

export const AppContext: Context<typeof appSettings> = createContext(appSettings)

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (!context) throw new Error('App settings error')

  return context
}
