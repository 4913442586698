import { lazy, Suspense } from 'react'

import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { selectActiveList } from '@/main/features/List/listSlice'
import { useListHistory } from '@/main/features/List/hooks/useListHistory'
import { selectItemsByListId } from '@/main/features/Item/itemSlice'

const ListSnackbarPortal = lazy(() => import('./ListSnackbarPortal'))

export function ListSnackbar() {
  const list = useAppSelector(selectActiveList)
  const items = useAppSelector((state) => selectItemsByListId(state, list?.id))
  const listHistory = useListHistory(list, items)
  const changes = listHistory && listHistory.delta

  return !changes || !list ? null : (
    <Suspense fallback={null}>
      <ListSnackbarPortal changes={changes} listId={list.id} listName={list.name} />
    </Suspense>
  )
}
