import { useCallback, useState } from 'react'
import { ListPlus } from '@phosphor-icons/react'

import { addList, ListId, selectLists } from '@/main/features/List/listSlice'

import { Button } from '@/main/components/Button/Button'
import { Modal } from '@/main/components/Modal/Modal'

import { useAppDispatch, useAppSelector } from '@/main/app/hooks/storeHooks'
import { inputClassList, labelClassList } from '@/main/app/utils/classNames'

export function ListCreateDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: (id?: ListId) => void
}) {
  const lists = useAppSelector(selectLists)
  const dispatch = useAppDispatch()
  const [name, setName] = useState<string>()

  const handleClose = useCallback(
    (id?: ListId) => {
      setName(undefined)
      onClose(id)
    },
    [onClose]
  )

  const onCreate = useCallback(async () => {
    if (!name) return

    const { id } = await dispatch(addList({ name })).unwrap()

    handleClose(id)
  }, [dispatch, handleClose, name])

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} onConfirm={onCreate}>
      <Modal.Title>Create New Project</Modal.Title>
      <Modal.Description>
        {lists.length > 9 ? (
          <span className="twc-text-red-500">
            The maximum number of 10 projects has been reached. Select a project, then Delete it
            using the options menu.
          </span>
        ) : (
          <>Create and switch to a new cart to work on a new request or project.</>
        )}
      </Modal.Description>

      <Modal.Body>
        <label className={labelClassList}>
          Name
          <br />
          <input
            className={inputClassList}
            type="text"
            disabled={lists.length > 9}
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>

        <p>
          Use the{' '}
          <span className="twc-bg-sky-200 dark:twc-bg-sky-800 dark:twc-text-white twc-font-medium twc-px-1.5 twc-py-1 twc-ml-0.5 twc-text-sm twc-rounded">
            Options
          </span>{' '}
          menu, or click on the project title, to switch between carts.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => handleClose()} size="large" variant="outline">
          Cancel
        </Button>

        <Button size="large" onClick={onCreate} disabled={!name || lists.length > 9}>
          Create New Project <ListPlus />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
