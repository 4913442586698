import { useState, useEffect, Dispatch, SetStateAction } from 'react'

const getPersistedValue = (key: string) => {
  try {
    const value = window.localStorage.getItem(key)

    return !value ? '' : value
  } catch (_) {
    return ''
  }
}

export const usePersistedValue = (key: string) => {
  const [value, setValue] = useState(() => getPersistedValue(`ACMEBK_${key}`))

  const removeValue = () => {
    window.localStorage.removeItem(`ACMEBK_${key}`)
  }

  useEffect(() => window.localStorage.setItem(`ACMEBK_${key}`, `${value}`), [key, value])

  return [value, setValue, removeValue] as [string, Dispatch<SetStateAction<string>>, () => void]
}
