import { useIsFetching, useQueryClient } from 'react-query'

import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { formatCurrency } from '@/main/app/utils/formatCurrency'

import type { ListId } from '../../listSlice'

import { selectItemsByListId } from '@/main/features/Item/itemSlice'

export function ListSubtotal({ listId }: { listId?: ListId }) {
  const client = useQueryClient()
  const items = useAppSelector((state) => selectItemsByListId(state, listId))
  const data = client.getQueriesData<AcmeProp>(['item']).map(([_, item]) => item)

  const aggregate = items.reduce((sum, item) => {
    const itemData = data.find((entry) => entry?.id == item.postId)

    return itemData ? item.count * itemData.rate + sum : sum
  }, 0)

  const isNotReady = data.some((entry) => !entry)
  const isFetching = useIsFetching()

  if (!items || !items.length) {
    return <span className="twc-font-light">Your cart is empty</span>
  }

  if (isFetching) {
    return (
      <>
        <span className="twc-sr-only">Calculating</span>
        <span
          className="motion-safe:twc-animate-pulse twc-font-light twc-text-gray-500"
          aria-hidden="true"
        >
          $ {'\u2026'}
        </span>
      </>
    )
  }

  if (isNotReady) {
    return <span className="twc-font-light">Not available</span>
  }

  return <>{formatCurrency(aggregate)}</>
}
