import { useCallback, useEffect, useRef } from 'react'
import { Dialog } from '@headlessui/react'
import { X } from '@phosphor-icons/react'

import { Button } from '../Button/Button'

interface ModalComponentProps {
  children: React.ReactNode
}

interface ModalProps {
  isOpen: boolean
  onConfirm?: () => void
  onClose: () => void
  children: React.ReactNode
}

function Title({ children }: ModalComponentProps) {
  return (
    <Dialog.Title className="twc-prose-lg sm:twc-prose-xl twc-font-bold dark:twc-prose-invert">
      {children}
    </Dialog.Title>
  )
}

function Description({ children }: ModalComponentProps) {
  return (
    <Dialog.Description className="twc-prose-base sm:twc-prose-lg twc-prose dark:twc-prose-invert">
      {children}
    </Dialog.Description>
  )
}

function Body({ children }: ModalComponentProps) {
  return <div className="twc-prose dark:twc-prose-invert">{children}</div>
}

function Footer({ children }: ModalComponentProps) {
  return (
    <div className="twc-flex twc-items-center twc-justify-end twc-gap-2 twc-mt-8" role="group">
      {children}
    </div>
  )
}

function Modal({ isOpen, onClose, onConfirm, children }: ModalProps) {
  const focusRef = useRef<HTMLButtonElement>(null)

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Enter' && isOpen && onConfirm !== undefined) {
        onConfirm()
      }
    },
    [onConfirm, isOpen]
  )

  useEffect(() => {
    window.addEventListener('keyup', handleKeyDown)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <Dialog open={isOpen} onClose={onClose} initialFocus={focusRef}>
      <div
        className="twc-fixed twc-inset-0 twc-bg-slate-800/30 twc-z-[90] dark:twc-bg-slate-900/75"
        aria-hidden="true"
      />

      <div className="twc-fixed twc-inset-0 twc-flex twc-items-start twc-justify-center twc-p-4 sm:twc-p-6 twc-z-[99]">
        <Dialog.Panel className="twc-relative twc-mx-auto twc-w-full grow twc-max-w-lg twc-rounded twc-bg-white dark:twc-bg-zinc-900 twc-p-4 sm:twc-p-6 twc-my-12 twc-flex twc-flex-col twc-gap-4">
          <Button
            onClick={onClose}
            variant="ghost"
            className="twc-absolute twc-right-1 twc-top-2 hover:twc-opacity-50 focus:twc-ring focus:twc-ring-blue-500"
            ref={focusRef}
            id="close-modal"
          >
            <span className="twc-sr-only">Close</span>
            <X size={18} />
          </Button>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

Modal.Title = Title
Modal.Description = Description
Modal.Body = Body
Modal.Footer = Footer

export { Modal }
