import {
  format,
  isWeekend,
  formatDistanceToNow,
  formatRelative,
  isToday,
  differenceInDays,
  startOfToday,
  formatISO,
  // isDate,
  // toDate,
  // parseISO,
} from 'date-fns'

import enUS from 'date-fns/locale/en-US'

import { isHoliday } from './holidays'

// export const reformatDate = (dateInput: string, formatString: string) =>
//   format(parseISO(dateInput), formatString)

export const isWorkday = (date: Date) => !isWeekend(date) && !isHoliday(date)

// export const getFormattedDateRange = (format: string, starts?: string, ends?: string) => {
//   if (!starts || !ends) return null

//   try {
//     const startDate = reformatDate(starts, format)
//     const endDate = reformatDate(ends, format)

//     return `${startDate}\u2013${endDate}`
//   } catch (_err) {
//     return null
//   }
// }

const formatRelativeLocale = {
  lastWeek: "'last' eeee, MMM d",
  yesterday: "'yesterday' (EEE, MMM d)",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: 'EEEE, MMMM do',
}

const locale = {
  ...enUS,
  formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
}

export const formatDateRelativeToNow = (date: Date) => {
  if (isToday(date)) return formatDistanceToNow(date, { addSuffix: true })
  if (differenceInDays(startOfToday(), date) < 7)
    return formatRelative(date, new Date(), { locale })

  const distance = formatDistanceToNow(date, { addSuffix: true })

  return `${distance}, (${format(date, 'E, MM do')})`
}

// export const castToDate = (value: unknown) => {
//   if (value === null) return null
//   if (isDate(value)) return toDate(value as Date | number)

//   if (typeof value === 'string') {
//     try {
//       const parseDate = parseISO(value)

//       return parseDate
//     } catch (_) {
//       return null
//     }
//   }

//   return null
// }

export const formatShortDate = (date?: Date | number) =>
  new Intl.DateTimeFormat('default', {
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
  }).format(date)

export const formatLongDate = (date?: Date | number) =>
  new Intl.DateTimeFormat('default', {
    dateStyle: 'full',
  }).format(date)

type TYear = `${number}${number}${number}${number}`
type TMonth = `${number}${number}`
type TDay = `${number}${number}`

type DateStr = `${TYear}-${TMonth}-${TDay}`

export function isValidDateStr(str: string): str is DateStr {
  return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null
}

export function toDateStr(date: Date | string): DateStr {
  if (typeof date === 'string') {
    if (isValidDateStr(date)) {
      return date
    } else {
      throw new Error(`Invalid date string: ${date}`)
    }
  } else {
    const dateString = formatISO(date, { representation: 'date' })

    if (isValidDateStr(dateString)) {
      return dateString
    }
  }

  throw new Error(`Invalid input: ${date}`)
}
