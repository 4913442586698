import { useAppDispatch } from '@/main/app/hooks/storeHooks'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listActive, ListId } from '../listSlice'

export function useNavigateList() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const basepath = location.pathname.indexOf('cart') > -1 ? '/cart' : ''

  const onActivateList = useCallback(
    (id?: ListId) => {
      if (id === '/') {
        dispatch(listActive())
        navigate(basepath)
      } else {
        dispatch(listActive(id))
        navigate(`${basepath}/${id ?? ''}`)
      }
    },
    [navigate, dispatch, basepath]
  )

  return onActivateList
}
