import { QueryErrorResetBoundary } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'

import type { ItemId } from '@/main/features/Item/itemSlice'

import { ItemResetErrorBoundary } from './ItemResetErrorBoundary'

interface ItemBoundaryProps {
  id: ItemId
  children: React.ReactNode
}

export function ItemBoundary({ id, children }: ItemBoundaryProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ItemResetErrorBoundary id={id} error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}
