interface ListWhenProps {
  isEmpty: boolean | undefined
  isReady: boolean | null
  children: React.ReactNode
}

export function ListWhen({ isEmpty, isReady, children }: ListWhenProps) {
  if (!isReady) {
    return (
      <div className="twc-flex twc-justify-center twc-items-center twc-h-3/4">
        <div className="motion-safe:twc-animate-ping twc-h-6 twc-w-6 twc-rounded-full twc-bg-brand" />
      </div>
    )
  }

  if (isEmpty === undefined) {
    return <p>There was a problem loading the cart.</p>
  }

  if (isEmpty) {
    return (
      <div className="twc-prose dark:twc-prose-invert twc-py-6 twc-mx-auto">
        <h3 className="twc-text-xl twc-font-medium twc-text-center twc-mb-3">
          Your cart is empty!
        </h3>

        <p>
          <a href="/props">Browse our catalog</a> and press the{' '}
          <strong className="twc-font-extrabold">Add to Cart</strong> button to add props and start
          an order.
        </p>

        <p>
          <strong>Have multiple projects?</strong> Select the{' '}
          <strong className="twc-font-extrabold">Options</strong> menu, and choose the{' '}
          <strong className="twc-font-extrabold">Create New Project</strong> option. You can have up
          to (10) separate projects at a time.
        </p>

        <p>
          See our <a href="/rent">How to Rent</a> page for more info and answers to common
          questions, including how to order, pickup details, getting props delivered, and rental
          policies.
        </p>
      </div>
    )
  }

  return <>{children}</>
}
