import { useCallback, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  ArrowCounterClockwise,
  CaretDown,
  ListPlus,
  Circle,
  PencilSimple,
  TrashSimple,
  CheckCircle,
  NotePencil,
  Ghost,
  FilePdf,
  Link,
} from '@phosphor-icons/react'
import { DialogId, ListDialogs } from '../ListDialogs/ListDialogs'

import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { selectLists, ListId, selectListById } from '@/main/features/List/listSlice'

import { ThemeButton } from '@/main/components/ThemeButton/ThemeButton'
import { useParams } from 'react-router-dom'
import { useNavigateList } from '../../hooks/useNavigateList'
import { usePersistedValue } from '@/main/app/hooks/usePersistedValue'

interface ListMenuProps {
  align?: 'left' | 'right'
}

const baseStyle =
  'motion-safe:twc-transition-colors twc-inline-flex twc-items-center twc-text-sm twc-gap-1 twc-px-1 twc-rounded twc-w-full twc-py-1.5 disabled:twc-text-zinc-400 dark:twc-text-zinc-100 dark:disabled:twc-text-zinc-500 twc-my-0.5 ui-active:twc-bg-slate-200 ui-active:disabled:twc-bg-transparent ui-active:twc-text-black ui-active:disabled:twc-text-zinc-500 dark:ui-active:twc-bg-slate-700 dark:ui-active:twc-text-white dark:disabled:ui-active:twc-text-zinc-600'

const delayOpenDialog = (x: DialogId, cb: (o: DialogId) => void) => setTimeout(() => cb(x), 30)

export function ListMenu({ align = 'left' }: ListMenuProps) {
  const { id: listIdParam } = useParams()
  const [activeDialog, setActiveDialog] = useState<DialogId>()

  const [theme] = usePersistedValue('theme')

  const lists = useAppSelector(selectLists)
  const list = useAppSelector((state) => selectListById(state, listIdParam ?? ''))
  const listName = list?.name ?? 'Project'

  const onNavigateList = useNavigateList()

  const onOpenDialog = useCallback((dialog: DialogId) => setActiveDialog(dialog), [setActiveDialog])

  const onCloseDialog = useCallback(
    (id?: ListId) => {
      setActiveDialog(undefined)

      if (id === '/') {
        onNavigateList()
      } else if (id) {
        onNavigateList(id)
      }
    },
    [onNavigateList, setActiveDialog]
  )

  const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark').matches
  const themeIsSet = theme !== null && theme !== undefined
  const enableExperimentalColorMode = prefersDark || themeIsSet

  return (
    <>
      <Menu as="div" className="twc-relative twc-inline">
        <Menu.Button className="twc-inline-flex twc-gap-1 twc-items-center twc-rounded-lg twc-text-sm md:twc-text-base twc-px-1.5 twc-leading-loose md:twc-leading-loose twc-bg-sky-200 twc-border-0 hover:twc-bg-sky-100 dark:twc-bg-slate-700 dark:twc-text-slate-50 dark:hover:twc-bg-sky-700 motion-safe:twc-transition-colors">
          Options <CaretDown weight="bold" aria-hidden="true" />
        </Menu.Button>

        <Transition
          enter="twc-transition twc-duration-300 twc-ease-out"
          enterFrom="twc-opacity-0"
          enterTo="twc-opacity-100"
          leave="twc-transition twc-duration-100 twc-ease-out"
          leaveFrom="twc-opacity-100"
          leaveTo="twc-opacity-0"
          className="twc-z-[60] twc-relative"
        >
          <Menu.Items
            className={`${
              align === 'right' ? 'twc-right-0' : 'twc-left-0'
            } twc-absolute origin-top-left twc-mt-2 twc-bg-white dark:twc-bg-zinc-800 twc-w-56 twc-rounded-md twc-shadow-md twc-divide-y twc-divide-slate-300 dark:twc-divide-zinc-600`}
          >
            <div className="twc-p-1">
              <p className="twc-text-xs twc-p-1 twc-font-medium twc-text-slate-700 dark:twc-text-zinc-300">
                My projects
              </p>
              {!lists?.length ? (
                <Menu.Item>
                  <button className={baseStyle} disabled>
                    <Ghost />
                    No projects
                  </button>
                </Menu.Item>
              ) : (
                lists?.map((l) => (
                  <Menu.Item key={l.id}>
                    <button
                      onClick={() => onNavigateList(l.id)}
                      className={`${baseStyle} ${
                        l.id === list?.id ? 'twc-font-medium' : 'twc-font-normal'
                      }`}
                    >
                      {l.id === list?.id ? (
                        <CheckCircle weight="bold" className="twc-shrink-0" />
                      ) : (
                        <Circle className="twc-text-slate-300 shrink-0" />
                      )}
                      <span className="twc-text-ellipsis twc-overflow-hidden twc-whitespace-nowrap">
                        {l.name}
                      </span>
                    </button>
                  </Menu.Item>
                ))
              )}
            </div>
            <div className="twc-p-1">
              <p className="twc-text-xs twc-p-1 twc-font-medium twc-text-slate-700 dark:twc-text-zinc-300 twc-text-ellipsis twc-overflow-hidden twc-whitespace-nowrap">
                Share &quot;{listName}&quot;
              </p>
              <Menu.Item>
                <button
                  onClick={() => delayOpenDialog('share', onOpenDialog)}
                  className={baseStyle}
                >
                  <Link /> Copy link
                </button>
              </Menu.Item>
              <Menu.Item>
                <button onClick={() => delayOpenDialog('pdf', onOpenDialog)} className={baseStyle}>
                  <FilePdf /> Download PDF
                </button>
                {/* <ListPdfButton listId={list?.id} className={baseStyle} /> */}
              </Menu.Item>
            </div>

            <div className="twc-p-1">
              <p className="twc-text-xs twc-p-1 twc-font-medium twc-text-slate-700 dark:twc-text-zinc-300 twc-text-ellipsis twc-overflow-hidden twc-whitespace-nowrap">
                Edit &quot;{listName}&quot;
              </p>
              <Menu.Item>
                <button
                  className={baseStyle}
                  onClick={() => delayOpenDialog('rename', onOpenDialog)}
                  disabled={!lists?.length || !list?.id}
                >
                  <PencilSimple /> Rename
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => delayOpenDialog('clear', onOpenDialog)}
                  className={baseStyle}
                  disabled={!lists?.length || !list?.id}
                >
                  <ArrowCounterClockwise className="twc-pointer-events-none" aria-hidden="true" />{' '}
                  Remove all props
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  disabled={!lists?.length || !list?.id}
                  onClick={() => delayOpenDialog('delete', onOpenDialog)}
                  className={baseStyle}
                >
                  <TrashSimple className="twc-pointer-events-none shrink-0" aria-hidden="true" />
                  <p className="twc-text-left twc-text-ellipsis twc-overflow-hidden twc-whitespace-nowrap">
                    Delete &quot;{listName}&quot;
                  </p>
                </button>
              </Menu.Item>
            </div>

            <div className="twc-p-1">
              <Menu.Item>
                <button onClick={() => onNavigateList()} className={baseStyle}>
                  <NotePencil weight="bold" /> Select/edit projects
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => delayOpenDialog('create', onOpenDialog)}
                  className={baseStyle}
                >
                  <ListPlus weight="bold" /> Create new project
                </button>
              </Menu.Item>
            </div>
            {enableExperimentalColorMode && (
              <div className="twc-p-1">
                <Menu.Item>
                  <ThemeButton className={baseStyle} />
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>

      <ListDialogs
        listId={list?.id}
        listName={listName}
        activeDialog={activeDialog}
        onCloseDialog={onCloseDialog}
      />
    </>
  )
}
