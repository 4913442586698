import { useCallback, useState } from 'react'

import { listRenamed, ListId } from '@/main/features/List/listSlice'

import { Button } from '@/main/components/Button/Button'
import { Modal } from '@/main/components/Modal/Modal'
import { NotePencil } from '@phosphor-icons/react'
import { useAppDispatch } from '@/main/app/hooks/storeHooks'
import { inputClassList, labelClassList } from '@/main/app/utils/classNames'

export function ListRenameDialog({
  listId,
  listName = 'your cart',
  isOpen,
  onClose,
}: {
  listId?: ListId
  listName?: string
  isOpen: boolean
  onClose: () => void
}) {
  const dispatch = useAppDispatch()
  const [name, setName] = useState<string>()

  const handleClose = useCallback(() => {
    setName(undefined)
    onClose()
  }, [onClose])

  const onRename = useCallback(() => {
    if (!listId) return
    if (!name) return

    dispatch(listRenamed({ id: listId, name }))
    handleClose()
  }, [dispatch, handleClose, listId, name])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} onConfirm={onRename}>
      <Modal.Title>Rename &quot;{listName}&quot;?</Modal.Title>

      <Modal.Body>
        <label className={labelClassList}>
          New name
          <br />
          <input
            className={inputClassList}
            type="text"
            placeholder={listName}
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>

        <p>
          <strong>More than one project?</strong> Make a new request!
          <br />
          Go to{' '}
          <span className="twc-bg-sky-200 dark:twc-bg-sky-800 dark:twc-text-white twc-font-medium twc-px-1.5 twc-py-1 twc-ml-0.5 twc-text-sm twc-rounded">
            Options &gt; Create new project
          </span>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => handleClose()} size="large" variant="outline">
          Cancel
        </Button>

        <Button disabled={!name || name === ''} size="large" onClick={onRename}>
          Rename <NotePencil />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
