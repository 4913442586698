import { Popover, Transition } from '@headlessui/react'
import { ButtonHTMLAttributes, forwardRef, Fragment } from 'react'
import { useTooltip } from './useTooltip'

const TooltipButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  function TooltipButton(props, ref) {
    return (
      <button
        aria-label={props.title}
        className="twc-inline-flex twc-items-center"
        onClick={props.onClick}
        ref={ref}
      >
        {props.children}
      </button>
    )
  }
)

export function Tooltip({
  align = 'left',
  width = 'twc-w-72 sm:twc-w-80',
  title,
  icon,
  children,
}: {
  align?: 'left' | 'right' | 'center'
  width?: string
  title: string
  icon: React.ReactNode
  children: React.ReactNode
}) {
  const { buttonRef, onHover, onClick } = useTooltip()

  return (
    <Popover className="twc-inline-flex twc-relative twc-items-center twc-mx-1">
      {({ open }) => (
        <div
          className="twc-relative twc-z-10 twc-flex twc-flex-col"
          onMouseEnter={() => onHover(open, 'onMouseEnter')}
          onMouseLeave={() => onHover(open, 'onMouseLeave')}
        >
          <Popover.Button
            ref={buttonRef}
            as={TooltipButton}
            onClick={() => onClick(open)}
            title={title}
          >
            {icon}
          </Popover.Button>

          <Transition
            as={Fragment}
            show={open}
            enter="twc-transition twc-ease-out twc-duration-200"
            enterFrom="twc-opacity-0 twc-translate-y-1"
            enterTo="twc-opacity-100 twc-translate-y-0"
            leave="twc-transition twc-ease-in twc-duration-150"
            leaveFrom="twc-opacity-100 twc-translate-y-0"
            leaveTo="twc-opacity-0 twc-translate-y-1"
          >
            <Popover.Panel className={`twc-z-10`} static>
              <div
                className={`twc-p-2 twc-absolute ${
                  align === 'right'
                    ? '-twc-right-2.5 twc-translate-x-1/2 xs:twc-translate-x-0'
                    : align === 'center'
                    ? '-twc-translate-x-1/2 twc-left-4 sm:-twc-x-1/2 sm:twc-left-0'
                    : '-twc-translate-x-1/4 twc-left-0 sm:twc-translate-x-0'
                } twc-font-medium twc-mt-1 ${width} twc-rounded twc-shadow-lg twc-bg-teal-900 twc-text-white`}
              >
                {children}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  )
}
