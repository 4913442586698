import { KEY_PREFIX, REHYDRATE } from 'redux-persist/es/constants'
import type { Store } from 'redux'
import type { EntityId } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import { toggleAllPdpCartButtons } from '@/main/app/utils/pdpButtons'

type PersistState = Pick<RootState, 'items' | 'lists' | '_persist'>
type StateKeys = keyof PersistState

export function syncTabs(store: Store<PersistState>, key: string) {
  window.addEventListener('storage', handleStorageEvent, false)

  function handleStorageEvent(e: StorageEvent) {
    if (e.key !== null && e.key.indexOf(KEY_PREFIX) === 0 && e.key.indexOf(key) > -1) {
      if (e.oldValue === e.newValue) return

      try {
        const nextValue = e.newValue || '{}'
        const statePartial = JSON.parse(nextValue) as Record<StateKeys, string>

        const state = Object.keys(statePartial).reduce((state, reducerKey) => {
          state[reducerKey as StateKeys] = JSON.parse(statePartial[reducerKey as StateKeys])

          return state
        }, {} as PersistState)

        store.dispatch({
          key,
          payload: state,
          type: REHYDRATE,
        })

        const activeListId = state.lists.activeId

        if (activeListId) {
          const activeList = state.lists.entities[activeListId]
          const entityIds = Object.keys(state.items.entities) as EntityId[]
          const activeItems = entityIds.reduce((items, id) => {
            const item = state.items.entities[id]

            if (item) {
              return [...items, item.postId]
            }

            return items
          }, [] as EntityId[])

          toggleAllPdpCartButtons({ ids: activeItems, listName: activeList?.name })
        }
      } catch (err) {
        console.error('Sync failed', err)
      }
    }
  }
}
