import { formatDateRelativeToNow, formatShortDate } from '@/main/app/utils/dates'

import type { Item } from '@/main/features/Item/itemSlice'
import type { ItemWithData, List } from '@/main/features/List/listSlice'
import { parseISO } from 'date-fns/esm'

interface Compare {
  isNotEqual: (Item & { change: number })[]
  isEqual: Item[]
}

function differenceById(left: (Item | ItemWithData)[], right: (Item | ItemWithData)[]) {
  return left.filter((l) => !right.some((r) => r.postId == l.postId))
}

export function useListHistory(list: List | undefined, items: Item[]) {
  if (!list) return undefined
  if (!list.history) return false

  const previousForm = {
    name: `${list.history.profile.firstName} ${list.history.profile.lastName}`.trim(),
    email: `${list.history.profile.email}`.trim(),
    phone: `${list.history.profile.phone}`.trim(),
    client: `${list.history.billing.client}`.trim(),
    reference: `${list.history.billing.reference}`.trim(),
    cc: `${list.history.billing.cc}`.trim(),
    startDate: `${list.history.dates.startDate}`.trim(),
    endDate: `${list.history.dates.endDate}`.trim(),
    shippingMethod: `${list.history.shipping.shippingMethod}`.trim(),
  }

  const nextForm = {
    name: `${list.form?.profile?.values.firstName} ${list.form?.profile?.values.lastName}`.trim(),
    email: `${list.form?.profile?.values.email}`.trim(),
    phone: `${list.form?.profile?.values.phone}`.trim(),
    client: `${list.form?.billing?.values.client}`.trim(),
    reference: `${list.form?.billing?.values.reference}`.trim(),
    cc: `${list.form?.billing?.values.cc}`.trim(),
    startDate: `${list.form?.dates?.values.startDate}`.trim(),
    endDate: `${list.form?.dates?.values.endDate}`.trim(),
    shippingMethod: `${list.form?.shipping?.values?.shippingMethod}`.trim(),
  }

  const shippingHistory = {
    previous:
      list.history.shipping.shippingMethod === 'delivery' &&
      JSON.stringify(list.history.shipping.shippingDetail),
    next:
      list?.form?.shipping?.values?.shippingMethod === 'delivery' &&
      list.form.shipping.values.shippingDetail !== undefined &&
      JSON.stringify(list.form.shipping.values.shippingDetail),
  }

  const formHistory = {
    Name: previousForm.name !== nextForm.name && [previousForm.name, nextForm.name],
    Email: previousForm.email !== nextForm.email && [previousForm.email, nextForm.email],
    Phone: previousForm.phone !== nextForm.phone && [previousForm.phone, nextForm.phone],
    Client: previousForm.client !== nextForm.client && [previousForm.client, nextForm.client],
    Reference: previousForm.reference !== nextForm.reference && [
      previousForm.reference,
      nextForm.reference,
    ],
    CC: previousForm.cc !== nextForm.cc && [previousForm.cc, nextForm.cc],
    'Start Date': previousForm.startDate !== nextForm.startDate && [
      previousForm.startDate,
      nextForm.startDate,
    ],
    'End Date': previousForm.endDate !== nextForm.endDate && [
      previousForm.endDate,
      nextForm.endDate,
    ],
    'Shipping Method': previousForm.shippingMethod !== nextForm.shippingMethod && [
      previousForm.shippingMethod,
      nextForm.shippingMethod,
    ],
    'Delivery Details': previousForm.shippingMethod === 'delivery' &&
      nextForm.shippingMethod === 'delivery' &&
      shippingHistory.previous !== shippingHistory.next && [
        shippingHistory.previous || '',
        shippingHistory.next || '',
      ],
  }

  const startDateHistory =
    previousForm.startDate !== nextForm.startDate &&
    `Start date changed from ${formatShortDate(
      parseISO(previousForm.startDate)
    )} to ${formatShortDate(parseISO(nextForm.startDate))}`
  const endDateHistory =
    previousForm.endDate !== nextForm.endDate &&
    `Start date changed from ${formatShortDate(
      parseISO(previousForm.endDate)
    )} to ${formatShortDate(parseISO(nextForm.endDate))}`

  const itemHistory = Object.fromEntries(list.history.items.map((item) => [item.postId, item]))

  const compare = items.reduce(
    (all: Compare, item) => {
      if (!itemHistory[item.postId]) return all

      if (itemHistory[item.postId].count === item.count) {
        all.isEqual.push(item)
      } else {
        all.isNotEqual.push({ ...item, change: item.count - itemHistory[item.postId].count })
      }

      return all
    },
    { isNotEqual: [], isEqual: [] }
  )

  const itemsAdded = differenceById(items, list.history.items)
  const itemsRemoved = differenceById(list.history.items, items)
  const itemsChanged = compare?.isNotEqual ?? []
  const itemsKept = compare?.isEqual ?? []

  return {
    add: itemsAdded,
    remove: itemsRemoved,
    change: itemsChanged,
    keep: itemsKept,
    delta: itemsAdded.length + itemsRemoved.length + itemsChanged.length,
    lastSent: formatDateRelativeToNow(new Date(list.history.sent)),
    startDateHistory,
    endDateHistory,
    formHistory,
  }
}
