import { Fragment, Suspense, useState } from 'react'
import { QueryErrorResetBoundary } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { CircleNotch, DotsThreeVertical, TrashSimple } from '@phosphor-icons/react'

import type { EntityId } from '@reduxjs/toolkit'

import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { ItemImage } from '@/main/features/Item/components/ItemImage/ItemImage'
import { useItemQuery } from '@/main/features/Item/hooks/useItemQuery'
import { selectItemById, selectItemsByListId } from '@/main/features/Item/itemSlice'
import { Button } from '@/main/components/Button/Button'

import type { List } from '../../listSlice'

import { useListHistory } from '../../hooks/useListHistory'
import { Popover, Transition } from '@headlessui/react'
import { ListDeleteDialog } from '../ListDialogs/ListDeleteDialog/ListDeleteDialog'
import { useNavigateList } from '../../hooks/useNavigateList'

function ItemCover({ id }: { id: EntityId }) {
  const item = useAppSelector((state) => selectItemById(state, id))
  const { data } = useItemQuery(item?.postId)

  return data?.media ? <ItemImage media={data?.media} title={data.title} /> : null
}

function ItemImageBoundary({ children }: { children: React.ReactNode }) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <Button size="small" variant="outline" onClick={() => resetErrorBoundary()}>
              Retry
            </Button>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

export function ListSelectCard({ list }: { list: List }) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const items = useAppSelector((state) => selectItemsByListId(state, list.id))
  const count = items.reduce((sum, item) => item.count + sum, 0)
  const listHistory = useListHistory(list, items)

  const onNavigate = useNavigateList()

  const bg = !listHistory
    ? 'twc-bg-blue-100/25 dark:twc-bg-blue-900/25'
    : listHistory.change.length > 0 || listHistory.add.length > 0 || listHistory.remove.length > 0
    ? 'twc-bg-orange-100/25 dark:twc-bg-pink-400/20'
    : 'twc-bg-emerald-100/25 dark:twc-bg-emerald-700/25'

  return (
    <>
      <div
        className={`twc-shadow twc-rounded ${bg} twc-border dark:twc-border-slate-800 twc-p-3 xl:twc-p-4 twc-flex twc-items-start md:twc-items-center`}
      >
        <div className="twc-aspect-4/5 twc-bg-zinc-900/10 twc-grow-0 twc-shrink-0 twc-basis-16 md:twc-basis-20 twc-mt-1 md:twc-mt-0 twc-flex twc-mr-3 md:twc-mr-4 twc-items-center twc-justify-center twc-leading-normal">
          {list.itemIds.length > 0 ? (
            <ItemImageBoundary>
              <Suspense fallback={<CircleNotch className="twc-animate-spin" />}>
                <ItemCover id={list.itemIds[0]} />
              </Suspense>
            </ItemImageBoundary>
          ) : (
            <small className="twc-text-center twc-leading-tight twc-text-zinc-600 twc-p-1">
              Add props to this cart
            </small>
          )}
        </div>

        <div className="twc-flex-grow">
          <Button
            variant="link"
            className="!twc-px-0 !twc-h-auto !twc-leading-snug twc-text-left !twc-mb-px"
            onClick={() => onNavigate(list.id)}
          >
            {list.name} &rarr;
          </Button>

          <p className="twc-text-[13px] md:twc-text-sm twc-leading-tight">
            {list.form?.billing?.values.client &&
            list.form?.dates?.values.startDate &&
            list.form?.dates?.values.endDate
              ? `${new Intl.DateTimeFormat('default', {
                  month: 'numeric',
                  day: 'numeric',
                }).formatRange(
                  new Date(list.form.dates.values.startDate),
                  new Date(list.form.dates.values.endDate)
                )} (${list.form.billing.values.client})`
              : 'New project'}
          </p>
          <p className="twc-text-[13px] md:twc-text-sm twc-leading-tight">
            {list.itemIds.length === 0
              ? 'This project is empty'
              : `${list.itemIds.length} props (${count} total quantity)`}
          </p>

          <p className="twc-text-xs md:twc-text-[13px] twc-mt-4">
            {!listHistory ? 'Not yet submitted' : `Sent ${listHistory.lastSent}`}
          </p>
        </div>

        <div className="twc-ml-auto twc-grow-0 twc-basis-4 twc-self-start twc-flex twc-gap-1 twc-justify-end twc-items-center">
          <Popover className="twc-relative -twc-mr-2 -twc-mt-1">
            <Popover.Button>
              <DotsThreeVertical size={20} weight="bold" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="twc-transition twc-ease-out twc-duration-200"
              enterFrom="twc-opacity-0 twc-translate-y-1"
              enterTo="twc-opacity-100 twc-translate-y-0"
              leave="twc-transition twc-ease-in twc-duration-150"
              leaveFrom="twc-opacity-100 twc-translate-y-0"
              leaveTo="twc-opacity-0 twc-translate-y-1"
            >
              <Popover.Panel className="twc-absolute -twc-right-0.5 z-10 mt-3 -translate-x-1/2 transform px-4 sm:px-0">
                <div className="twc-bg-zinc-200 dark:twc-bg-zinc-600 twc-shadow-sm twc-m-1 twc-py-1 twc-rounded twc-w-20 twc-text-xs">
                  <button
                    className="twc-inline-flex dark:hover:twc-text-white dark:hover:twc-bg-zinc-700 twc-rounded-sm motion-safe:twc-transition-colors twc-items-center twc-gap-1 twc-leading-loose twc-px-2 twc-w-full twc-justify-start twc-py-0 hover:twc-text-rose-700"
                    onClick={() => setIsDeleteDialogOpen(true)}
                  >
                    <TrashSimple /> Delete
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>

      <ListDeleteDialog
        listId={list.id}
        listName={list.name}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </>
  )
}
